import { createTheme, ThemeProvider } from '@mui/material'
import { RouterProvider } from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import TimeAgo from 'javascript-time-ago'
import es from 'javascript-time-ago/locale/es'
import { router } from './router'

import theme from './theme'

function App() {
    const customTheme = createTheme(theme)
    TimeAgo.addDefaultLocale(es)
    TimeAgo.addLocale(es)

    return (
         <LocalizationProvider dateAdapter={AdapterDayjs}>    
            <ThemeProvider theme={customTheme}>
                    <RouterProvider router={router} />
                </ThemeProvider>
         </LocalizationProvider>
    )
}
export default App
