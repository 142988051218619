import React from 'react'
import {
    Box,
    Button,
    Container,
    Grid,
    ImageList,
    ImageListItem,
    Link,
    Typography
} from '@mui/material'
import Tabs from './Tabs'
import ProductCard from '../ProductCard'
import { useScreenDetector } from '../../hooks/useScreenDetector'

const CardsTab = ({ tabData }) => {
    const { isMobile } = useScreenDetector()
    const initialCards = isMobile
        ? tabData[0].data.slice(0, 4)
        : tabData[0].data

    const [activeTabIndex, setActiveTabIndex] = React.useState(0)
    const [currentCards, setCurrentCards] = React.useState(initialCards)

    const onChangeTab = (tabIndex) => (evt, value) => {
        const tab = tabIndex !== undefined ? tabIndex : value
        setActiveTabIndex(tab)
        if (isMobile) {
            const mobileCards = tabData[tab].data.slice(0, 4)
            setCurrentCards([...mobileCards])
        } else {
            setCurrentCards([...tabData[tab].data])
        }
    }

    return (
        <Box>
            <Container>
                <Grid container mb={1.5}>
                    <Grid item xs={12} md={9}>
                        <Typography
                            color="secondary"
                            variant="subtitle"
                            component="h3"
                            sx={{ fontSize: { xs: '20px', md: '24px' } }}>
                            Nuestros tours Favoritos
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        justifyContent="flex-end"
                        alignItems="center"
                        sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Button
                            variant="contained"
                            color="primary"
                            href="#"
                            sx={{
                                borderRadius: '16px',
                                height: 'fit-content',
                                color: '#ffffff',
                                display: 'grid',
                                textTransform: 'none'
                            }}>
                            Ver más tours
                        </Button>
                    </Grid>
                </Grid>
                <Tabs
                    tabs={tabData}
                    activeTab={activeTabIndex}
                    onChangeTab={onChangeTab}
                />
            </Container>
            {isMobile ? (
                <Grid container spacing={1} sx={{ my: '1.5rem', px: '1rem' }}>
                    {currentCards.map((tab, index) => (
                        <Grid key={`${tab.title}${index}`} item xs={6}>
                            <ProductCard {...tab} />
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <ul className="desktop-carousel">    
                    {currentCards.map((tab, index) => (
                        <li
                            key={`${tab.title}${index}`}
                             style={{
                                    marginRight: "1rem"
                                }}>
                            <ProductCard {...tab} />
                        </li>
                    ))}
                </ul>
            )}
            <Box
                sx={{
                    display: { xs: 'flex', md: 'none' },
                    justifyContent: 'center',
                    my: '3rem'
                }}>
                <Link
                    color="primary"
                    underline="none"
                    sx={{
                        fontSize: 20,
                        fontFamily: 'Poppins',
                        fontWeight: 700,
                        lineHeight: 1.5
                    }}>
                    Ver más tours
                </Link>
            </Box>
        </Box>
    )
}

export default CardsTab
