import React from "react"
import {
    Box,
    Container,
    Divider,
    Link,
    Typography
} from "@mui/material"
import dayjs from "dayjs"

import MainLayout from "../../components/MainLayout"
import Breadcrumbs from "../../components/Breadcrumbs"
import TagButton from "../../components/TagButton"
import ReviewRatings from "../../components/Tours/ReviewRatings"
import GridGallery from "../../components/GridGallery"
import DetailsSection from "../../components/Tours/DetailsSection"
import ItineraryItem from "../../components/Tours/ItineraryItem"
import Calendar from "../../components/Filters/Calendar"
import RadioButtonsFilter from "../../components/Filters/RadioButtonsFilter"
import AddItemsButtonsFilter from "../../components/Filters/AddItemsButtonsFilter"
import PaymentSummary from "../../components/Tours/PaymentSummary"
import CouponBox from "../../components/Tours/CouponBox"
import AdditionalInformationSection from "../../components/Tours/AdditionalInformationSection"
import FAQs from "../../components/Tours/FAQs"
import MediaCard from "../../components/ProductCard3"
import Reviews from "../../components/Tours/Reviews"

import { useScreenDetector } from "../../hooks/useScreenDetector"

import { ReactComponent as MedalIcon } from "../../assets/icons/medal.svg"
import { ReactComponent as ClockIcon } from "../../assets/icons/clock.svg"
import { ReactComponent as GlobeIcon } from "../../assets/icons/globe.svg"

import { tourData, topActivities, reviews } from "./tourData"
import { dataPage } from "../../utils/destinationPageMockData"

const AvailabilityButton = ({ styles = {} }) => (
    <TagButton
        text="Ver disponibilidad"
        href="#personalizar-btn"
        onClick={() => { }}
        sx={{
            fontWeight: 600,
            mt: "1rem",
            px: "1rem",
            py: ".4rem",
            textAlign: 'center',
            width: "100%",
            ...styles,
        }}
    />
)

const Tour = () => {
    const [expandedTabs, setExpandedTabs] = React.useState([])
    const [addedItineraries, setAddedItineraries] = React.useState([]);
    const [date, setDate] = React.useState(dayjs('2024-04-17'));
    const [participants, setParticipans] = React.useState([
        {
            title: "Adultos",
            subtitle: "(+12 años)",
            value: 0,
        },
        {
            title: "Niños",
            subtitle: "(2 - 11 años)",
            value: 0,
        },
        {
            title: "Bebés",
            subtitle: "(0 - 2 años)",
            value: 0,
        },
    ])
    const { isMobile } = useScreenDetector()

    const hashTags = topActivities.slice(0, 3)
    const parent = tourData.breadCrums[tourData.breadCrums.length - 1];
    const tourCards = dataPage.activities.slice(0, 3);
    const durationFilters = [
        "3-5 horas",
        "7-9 horas",
        "12-14 horas",
        "Indiferente",
    ]

    const breadcrumbs = [
        ...tourData.breadCrums,
        {
            name: tourData.title,
            path: "#"
        }
    ]
    
    const mockCharges = [
        {
            name: "Lorem ipsum",
            price: 300,
        },
        {
            name: "Lorem ipsum2",
            price: 200,
        },
        {
            name: "Lorem ipsum3",
            price: 100,
        },
    ]

    const onExpandTab = (tab) => {
        if (expandedTabs.includes(tab)) {
            setExpandedTabs(current => current.filter(item => item !== tab))
        } else {
            setExpandedTabs(current => [...current, tab])
        }
    }

    const onClickItinerary = (name) => {
        if (addedItineraries.find((item) => item.title === name)) {
            setAddedItineraries(current => current.filter(item => item.title !== name))
        } else {
            const found = tourData.itinerary.find((item) => item.title === name)
            setAddedItineraries(current => [...current, found])
        }
    }

    const handleChangeCalendar = (event, newDate) => {
        setDate(newDate)
    }

    const onModifyParticipants = (instruction) => (title) => {
        setParticipans(current => {
            const incremented = current.map(item => {
                if (item.title === title) {
                    const newValue = instruction === "add" ?
                        ++item.value :
                        --item.value
                    return {
                        ...item,
                        value: newValue,
                    }
                } else {
                    return item
                }
            })
            return incremented
        })
    }

    return (
        <MainLayout>
            <Box sx={{
                backgroundColor: "#ffffff",
                height: "fit-content",
                width: "100%",
            }}>
                <Container>
                    <Breadcrumbs breadcrumbs={breadcrumbs} />
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "1rem",
                        mb: { xs: "1rem", md: "2rem" },
                    }}>
                        <Box sx={{ width: "70%" }}>
                            <Typography
                                component="h1"
                                sx={{
                                    color: "#3E3A39",
                                    fontSize: { xs: 20, md: 32 },
                                    fontFamily: 'Poppins',
                                    fontWeight: 700,
                                    lineHeight: 1.5,
                                }}
                            >
                                {tourData.title}
                            </Typography>
                            <Typography sx={{display: { xs: "none", md: "block" }, mt: "1rem"}}>
                                {tourData.description}
                            </Typography>
                            <Typography sx={{
                                alignItems: "center",
                                border: "1px solid #223F7F",
                                borderRadius: "20px",
                                display: { xs: "none", md: "flex" },
                                flexDirection: "row",
                                mt: "1rem",
                                py: "2px",
                                pl: "4px",
                                pr: "6px",
                                width: "fit-content"
                            }}>
                                <MedalIcon color="primary" />
                                Distintivo de excelencia
                            </Typography>
                            <Box sx={{display: { xs: "none", md: "block" }, mt: "1rem"}} >
                                <ReviewRatings rate={tourData.rate} reviewsCount={reviews.count} />
                            </Box>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "30%",
                            textAlign: "right",
                            pl: "15%",
                        }}>
                            <Typography
                                color="secondary"
                                component="p"
                                sx={{
                                    fontSize: { xs: 20, md: 24 },
                                    fontFamily: 'Poppins',
                                    fontWeight: 700,
                                    lineHeight: 1.5,
                                }}
                            >
                                {`Desde ${tourData.price}€`}
                            </Typography>
                            <Typography
                                color="grayText"
                                component="span"
                                sx={{
                                    fontSize: 12,
                                    fontFamily: 'Inter',
                                    fontWeight: 400,
                                    lineHeight: 1.5,
                                    textWrap: "nowrap",
                                }}
                            >
                                IVA incluido
                            </Typography>
                            <AvailabilityButton styles={{ display: { xs: "none", md: "block" }} } />
                        </Box>
                    </Box>
                    <Box sx={{ mb: {xs: "1rem", md: "3rem"}}}>
                        <GridGallery images={tourData.images} alt={tourData.title} />
                    </Box>
                    <Typography sx={{display: { xs: "block", md: "none" }}}>
                        {tourData.description}
                    </Typography>
                    <Box sx={{display: { xs: "block", md: "none" }, mt: "1rem"}} >
                        <ReviewRatings rate={tourData.rate} reviewsCount={reviews.count} />
                    </Box>
                    <AvailabilityButton styles={{ display: { xs: "block", md: "none" }, mb: "2rem" }} />
                    <Box sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        width: "100%"
                    }}>
                        <Box sx={{ width: {xs: "100%", md: "70%"}}}>
                            <DetailsSection
                                title="Sobre el tour"
                                isExpanded={
                                    !isMobile ? true : expandedTabs.includes("Sobre el tour")
                                }
                                onCollapse={onExpandTab}
                            >
                                <Box sx={{ my: "2rem" }}>
                                    <Box sx={{
                                        alignItems: "center",
                                        display: "flex",
                                        flexDirection: "row",
                                        mb: "1rem",
                                    }}>
                                        <ClockIcon width={24} height={24} />
                                        <Typography color="secondary" variant="subtitle3" sx={{ml: "4px"}}>
                                            {
                                                tourData.freeCancelation ?
                                                    "Cancelación gratuita" :
                                                    "Cancelación sin reembolso"
                                            }
                                        </Typography>
                                    </Box>
                                    <Typography sx={{ fontSize: 16, fontFamily: 'Inter' }}>
                                        Lorem ipsum dolor sit amet consectetur. Placerat quisque elementum viverra quis pellentesque mi. Congue maecenas ut.
                                    </Typography>
                                </Box>
                                <Box sx={{ mb: "2rem" }}>
                                    <Box sx={{
                                        alignItems: "center",
                                        display: "flex",
                                        flexDirection: "row",
                                        mb: "1rem",
                                    }}>
                                        <GlobeIcon width={24} height={24} />
                                        <Typography color="secondary" variant="subtitle3" sx={{ml: "4px"}}>
                                            Idioma: Inglés, español y chino
                                        </Typography>
                                    </Box>
                                    <Typography sx={{ fontSize: 16, fontFamily: 'Inter' }}>
                                        Lorem ipsum dolor sit amet consectetur. Placerat quisque elementum viverra quis pellentesque mi. Congue maecenas ut.
                                    </Typography>
                                </Box>
                            </DetailsSection>
                            <DetailsSection
                                title="Detalles del tour"
                                isExpanded={
                                    !isMobile ? true : expandedTabs.includes("Detalles del tour")
                                }
                                onCollapse={onExpandTab}
                            >
                                <Box sx={{
                                    borderBottom: "1px solid #666B6E",
                                    display: "flex",
                                    flexDirection: { xs: "column", md: "row" },
                                    mt: "2rem",
                                    width: "100%",
                                    pb: "1rem",
                                }}>
                                    <Typography component="h3" color="secondary" sx={{
                                        fontSize: 16,
                                        width: { xs: "100%", md: "30%" },
                                    }}>
                                        Descripción 📖
                                    </Typography>
                                    <Typography sx={{ fontSize: 16, fontFamily: 'Inter' }}>
                                        {tourData.details.description}
                                    </Typography>
                                </Box>
                                <Box sx={{
                                    borderBottom: "1px solid #666B6E",
                                    display: "flex",
                                    flexDirection: { xs: "column", md: "row" },
                                    mt: "2rem",
                                    width: "100%",
                                    pb: "1rem",
                                }}>
                                    <Typography component="h3" color="secondary" sx={{
                                        fontSize: 16,
                                        width: { xs: "100%", md: "30%" },
                                    }}>
                                        Qué incluye ✅
                                    </Typography>
                                    <Typography sx={{ fontSize: 16, fontFamily: 'Inter' }}>
                                        {tourData.details.includes}
                                    </Typography>
                                </Box>
                                <Box sx={{
                                    borderBottom: "1px solid #666B6E",
                                    display: "flex",
                                    flexDirection: { xs: "column", md: "row" },
                                    my: "2rem",
                                    width: "100%",
                                    pb: "1rem",
                                }}>
                                    <Typography component="h3" color="secondary" sx={{
                                        fontSize: 16,
                                        width: { xs: "100%", md: "30%" },
                                    }}>
                                        Que NO incluye ⛔
                                    </Typography>
                                    <Typography sx={{ fontSize: 16, fontFamily: 'Inter' }}>
                                        {tourData.details.notIncludes}
                                    </Typography>
                                </Box>
                            </DetailsSection>
                            <DetailsSection
                                title="Itinerario sugerido"
                                isExpanded={
                                    !isMobile ? true : expandedTabs.includes("Itinerario sugerido")
                                }
                                onCollapse={onExpandTab}
                            >  
                                <Box sx={{mt: {xs: "1rem", md: "2rem"}}}>
                                    {
                                        tourData.itinerary.map(item => (
                                            <ItineraryItem
                                                key={item.title}
                                                {...item}
                                                added={addedItineraries.includes(item)}
                                                onClickAdd={onClickItinerary}
                                            />
                                        ))
                                    }
                                </Box>
                            </DetailsSection>
                        </Box>
                        <Box sx={{ width: { xs: "100%", md: "30%" } }}>
                            <Box sx={{ mt: {xs: "1rem", md: "2rem"}, pl: {xs: 0, md: "2rem"}}}>
                                <Calendar title="Seleccione una fecha" date={date} handleChange={handleChangeCalendar}/>
                            </Box>
                            <Box sx={{ mt: {xs: "1rem", md: "2rem"}, pl: {xs: 0, md: "2rem"}}}>
                                <AddItemsButtonsFilter
                                    title="Participantes"
                                    options={participants}
                                    onAdd={onModifyParticipants("add")}
                                    onRemove={onModifyParticipants("remove")}
                                />
                            </Box>
                            <Box sx={{ mt: {xs: "1rem", md: "2rem"}, pl: {xs: 0, md: "2rem"}}}>
                                <RadioButtonsFilter title="Duración" options={durationFilters} onChange={() => {}} />
                            </Box>
                            <Box sx={{ mt: { xs: "1rem", md: "2rem" }, pl: { xs: 0, md: "2rem" } }}>
                                <PaymentSummary items={mockCharges} iva={13} />
                            </Box>
                            <Box sx={{ mb: "3rem", mt: { xs: "1rem", md: "2rem" }, pl: { xs: 0, md: "2rem" } }}>
                                <CouponBox />
                            </Box>
                        </Box>
                    </Box>
                    <Divider aria-hidden="true" color='secondary' sx={{ height: 1 }} />
                    <AdditionalInformationSection sections={tourData.additionalInformation} />
                    <FAQs />
                    <Typography component="h2" color="secondary" sx={{
                        fontSize: {xs: 24, md: 32},
                        fontFamily: 'Poppins',
                        fontWeight: 'bolder',
                        lineHeight: 1.5,
                        mt: { xs: "3rem", md: "4rem" },
                        mb: { xs: "1rem", md: "2rem" },
                    }}>
                        Otras sugerencias
                    </Typography>
                    <Box sx={{
                        display: "grid",
                        gridTemplateColumns: { xs: "1", md: "repeat(3, 1fr)" },
                        gap: "1rem",
                        width: "100%",
                    }}>
                        {tourCards.map((tour, index) => (
                            <MediaCard
                                key={`${tour.title}${index}`}
                                {...tour}
                            />
                        ))}
                    </Box>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        mt: "2rem",
                    }}>
                        <TagButton
                            text={
                                isMobile ?
                                    "Ver más" :
                                    `Ver todos los tour disponibles en ${parent.name}`
                            }
                            href={parent.path}
                            color="white"
                            sx={{
                                borderRadius: 8,
                                border: "1px solid #40C5E8",
                                color: "#40C5E8",
                                px: "1rem",
                                py: ".4rem",
                            }}
                        />
                    </Box>
                    <Typography component="h2" color="secondary" sx={{
                        fontSize: {xs: 24, md: 32},
                        fontFamily: 'Poppins',
                        fontWeight: 'bolder',
                        lineHeight: 1.5,
                        mt: { xs: "3rem", md: "4rem" },
                        mb: "1rem",
                        textAlign: "center",
                    }}>
                        {`Top 10 actividades en ${parent.name}`}
                    </Typography>
                    <Box sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "1rem",
                        justifyContent: "center",
                        mb: { xs: "2rem", md: "3rem" },
                        py: "1rem",
                        px: { xs: ".5rem", md: "1.5rem" },
                    }}>
                        {
                            topActivities.map(tour => (
                                <TagButton key={tour.name} text={tour.name} href={tour.path} sx={{ height: "2rem" }} />
                            ))
                        }
                    </Box>
                    <Reviews average={tourData.rate} {...reviews} />
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        mt: "2rem",
                        mb: { xs: 0, md: "4rem" },
                        pb: {xs: "4rem", md: 0}
                    }}>
                        <TagButton
                            text="Ver más reviews"
                            href="#"
                            color="white"
                            sx={{
                                borderRadius: 8,
                                border: "1px solid #40C5E8",
                                color: "#40C5E8",
                                px: "1rem",
                                py: ".4rem",
                            }}
                        />
                    </Box>
                    <Box sx={{display: { xs: "none", md: "flex" }, pb: "5rem", gap: 2 }}>
                        {
                            hashTags.map(tag => (
                                <Link
                                    key={tag.name}
                                    href={tag.path}
                                    sx={{
                                        backgroundColor: "#F0F0F1",
                                        borderLeft: "4px solid #666B6E",
                                        color: "#666B6E",
                                        fontSize: 12.3,
                                        fontFamily: 'Poppins',
                                        fontWeight: 600,
                                        p: 1,
                                        textDecoration: "none"
                                    }}
                                >
                                    {`#${tag.name}`}
                                </Link>
                            ))
                        }
                    </Box>
                </Container>
            </Box>
        </MainLayout>
    )
}

export default Tour
