import React from 'react'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { useClickOutsideDetector } from '../../hooks/useClickOutsideDetector'
import dialCodes from './countryCodes.json'
import './styles.css'

const PhoneNumberInput = ({ onChange, placeholder }) => {
    const [value, setValue] = React.useState('')
    const [showDropdown, setShowDropdown] = React.useState(false)
    const [dialCode, setDialCode] = React.useState('+34')
    const wrapperRef = React.useRef(null);

    const onClickListItem = (code) => {
        setDialCode(code.dial_code)
        setShowDropdown(false)
    }

    const handleOnChangeInput = (evt) => {
        const currentValue = evt.currentTarget.value
        const re = /^[0-9\b]+$/
        if (currentValue === '' || re.test(currentValue) && currentValue.length <= 10){
            setValue(currentValue)
            onChange(`${dialCode}${currentValue}`)
        }
    }

    useClickOutsideDetector(wrapperRef, () => {
        setShowDropdown(false);
    })

    return (
        <div className='input-container'>
            <span className='dialcode-container'>
                {dialCode}
            </span>
            <button
                className='arrow-btn'
                onClick={() => setShowDropdown(current => !current)}>
                {showDropdown ? <KeyboardArrowUp  /> : <KeyboardArrowDown />}
            </button>
            <input
                className='phone-input'
                onChange={handleOnChangeInput}
                placeholder={placeholder}
                type='text'
                value={value}

            />
            {showDropdown ? (
                <ul className='country-list' ref={wrapperRef}>
                    {
                        dialCodes.map(code => (
                            <li key={code.name}>
                                <button
                                    className='list-btn'
                                    onClick={() => onClickListItem(code)}
                                >
                                    {code.name}
                                </button>
                            </li>
                        ))
                    }
                </ul>
            ) : null}
        </div>
    )
}

export default PhoneNumberInput;
