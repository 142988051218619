import trekzyVector from '../../assets/images/trekzy-vector.svg'
import './styles.css'

export const orientations = {
    bottomToTop: '360deg',
    rightToLeft: '180deg',
    toTopLeft: 'to top left'
}

export const opacities = {
    darker: ['8.76%', '63.22%', '99.94%'],
    dark: ['18.76%', '63.22%', '99.94%'],
    lighter: ['10.76%', '33.22%', '60.94%'],
    none: ['0', '0', '0']
}

const BlurImageWrapper = ({
    children,
    imageUrl,
    orientation = orientations.rightToLeft,
    renderVector = false,
    opacity = opacities.dark,
    styles = {},
    altText = ''
}) => (
    <div className="wrapper-container">
        <div
            style={{
                '--img': `url(${imageUrl})`,
                '--orientation': `${orientation}`,
                '--percentage1': `${opacity[0]}`,
                '--percentage2': `${opacity[1]}`,
                '--percentage3': `${opacity[2]}`,
                ...styles
            }}
            className="bgimg-container">
            {renderVector ? (
                <img src={trekzyVector} alt={altText} className="img-vector" />
            ) : null}
            {children}
        </div>
    </div>
)

export default BlurImageWrapper
