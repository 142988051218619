import React from 'react'
import { Button, Container } from '@mui/material'
import BlurImageWrapper from '../BlurImageWrapper'
import bgImage from '../../assets/images/people-map.png'
import './styles.css'

const GuidesBanner = () => (
    <BlurImageWrapper imageUrl={bgImage}>
        <Container
            sx={{
                height: { xs: '23rem', md: 665 },
                display: 'flex',
                flexDirection: 'column',
                justifyContent: { xs: 'center', md: 'left' },
                alignItems: { xs: 'center', md: 'normal' },
                py: { xs: '3rem', md: '5.5rem' }
            }}>
            <p className="title">
                Descubre nuestras{' '}
                <strong className="title-end">guías de viajes</strong>
            </p>
            <p className="description">
                Echa un vistazo a las guías para no perderte nada
            </p>
            <Button
                variant="contained"
                color="primary"
                href="/guias-de-viaje"
                sx={{
                    borderRadius: '16px',
                    color: '#ffffff',
                    height: 'fit-content',
                    fontWeight: 600,
                    mt: 'auto',
                    textTransform: 'none',
                    width: '12rem'
                }}>
                Ver guías de viajes
            </Button>
        </Container>
    </BlurImageWrapper>
)

export default GuidesBanner
