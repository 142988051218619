import { Box, Rating, Typography } from "@mui/material"
import React from "react"

export default function ReviewRatings({
    rate,
    reviewsCount,
}) {
    return (
        <Box sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            gap: ".5rem",
        }}>
            <Typography
                color="secondary.contrastText"
                sx={{
                    backgroundColor: "#223F7F",
                    borderRadius: "10px",
                    fontFamily: 'Poppins',
                    fontSize: 20,
                    fontWeight: 700,
                    lineHeight: 1.5,
                    py: ".4rem",
                    px: ".8rem",
                    width: "fit-content"
                }}
            >
                {rate}
            </Typography>
            <Rating
                name="read-only"
                value={rate / 2}
                max={5}
                size="medium"
                readOnly
                sx={{display: { xs: "none", md: "flex" }}}
            />
            <Typography sx={{ color: {xs: "#666B6E", md: "#40C5E8"}}}>
                {`(${reviewsCount} Reviews)`}
            </Typography>
        </Box>
    )
}