import React from "react"
import { Box, Rating, Typography } from "@mui/material"
import { nameAbbreviation } from "../../utils/formatText"
import ReactTimeAgo from "react-time-ago"

const Raiting = ({ raiting, range }) => (
    <>
         <Rating
            name="read-only"
            value={raiting}
            max={range}
            size="small"
            readOnly
        />
        <Typography
            component="p"
            sx={{
                display: "flex",
                fontSize: {xs: 10.5, md: 18},
                fontFamily: 'Poppins',
                fontWeight: { xs: 500, md: 400 },
                justifyContent: { xs: "right", md: "left" }
            }}
        >
            {`${raiting}/`}
            <Typography
                component="span"
                sx={{
                    fontSize: {xs: 7.5, md: 16},
                    fontFamily: 'Poppins',
                    fontWeight: 400,
                    pt: "3px",
                }}>
                {range}
            </Typography>
        </Typography>
    </>
)

const ReviewCard = ({
    clientName,
    comment,
    country,
    raiting,
    title,
    date,
}) => {
    return (
        <Box sx={{
            borderRadius: 2,
            boxShadow: 4,
            display: "flex",
            p: "1rem",
        }}>
            <Box sx={{
                display: { xs: "none", md: "block" },
                width: "15%"
            }}>
               <Raiting raiting={raiting} range={5} />
            </Box>
            <Box sx={{
                width: { xs: "100%", md: "85%" },
            }}>
                <Box sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: "1rem",
                }}>
                    <Box sx={{
                        alignItems: "center",
                        display: "flex",
                        gap: 2,
                    }}>
                        <Typography sx={{
                            backgroundColor: "#223F7F",
                            borderRadius: "50%",
                            color: "#ffffff",
                            fontSize: {xs: 11, md: 15},
                            fontFamily: 'Poppins',
                            height: "fit-content",
                            fontWeight: 700,
                            p: 1,
                        }}>
                            {nameAbbreviation(clientName)}
                        </Typography>
                        <Box>
                            <Typography color="blackArticle" sx={{
                                fontSize: {xs: 10.5, md: 18},
                                fontFamily: 'Poppins',
                                fontWeight: { xs: 600, md: 700 },
                            }}>
                                {clientName}
                            </Typography>
                            <Typography color="blackArticle" sx={{
                                fontSize: {xs: 10.5, md: 16},
                                fontFamily: 'Poppins',
                                fontWeight: { xs: 400, md: 400 },
                            }}>
                                {country}
                            </Typography>
                            <Typography color="grayText" sx={{
                                display: {xs: "block", md: "none"},
                                fontSize: 10,
                                fontFamily: 'Poppins',
                                fontWeight: 400,
                            }}>
                                <ReactTimeAgo date={date} locale="es-ES"/>
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: { xs: "block", md: "none" }}}>
                        <Raiting raiting={raiting} range={5} />
                    </Box>
                    <Typography color="grayText" sx={{
                        display: {xs: "none", md: "block"},
                        fontSize: 16,
                        fontFamily: 'Poppins',
                        fontWeight: 400,
                    }}>
                        <ReactTimeAgo date={date} locale="es-ES"/>
                    </Typography>
                </Box>
                <Typography color="secondary" sx={{
                    fontSize: {xs: 13.5, md: 20},
                    fontFamily: 'Poppins',
                    fontWeight: 700,
                    lineHeight: 1.2,
                    mb: ".5rem",
                }}>
                    {title}
                </Typography>
                <Typography color="grayText" sx={{
                    fontSize: {xs: 12, md: 18},
                    fontFamily: 'Inter',
                    fontWeight: 400,
                    lineHeight: 1.5,
                }}>
                    {comment}
                </Typography>
            </Box>
        </Box>
    )
}

export default ReviewCard
