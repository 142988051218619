import React from 'react'
import Hero from '../../components/Hero';
import heroImage from '../../assets/images/paris.png'
import Breadcrumbs from '../../components/Breadcrumbs';
import { Container } from '@mui/material';
import CardsGrid from '../../components/CardsGrid';
import MainLayout from '../../components/MainLayout';
import { opacities } from '../../components/BlurImageWrapper';

const mockedCard = {
    name: 'Paris',
    activities: 90000,
    path: 'destinos/francia/paris',
    image: require('../../assets/images/paris.png')
}

const DestinationsCountry = () => {
    return (
        <MainLayout>
            <Hero
                bgImage={heroImage}
                opacity={opacities.lighter}
                renderInputSearch={false}
                title='Francia'
                titleHeader='Tours privados'
            />
            <Container>
                <Breadcrumbs breadcrumbs={[
                    {
                        name: 'Destinos',
                        path: 'destinos'
                    },
                    {
                        name: 'Francia',
                        path: '#'
                    }
                ]} />
                <CardsGrid cards={Array(12).fill(mockedCard)} />
            </Container>
        </MainLayout>
    )
}

export default DestinationsCountry
