import React from "react";

import { Grid, TextField } from "@mui/material";

const CmLabel = (props) => {
    const { children, ...lProps } = props;
    return (
        <label style={{color: '#6D7280', fontSize: '14px'}} {...lProps}>{children}</label>
    );
}

const FormTitular = ({formData, handleChange}) => {
    return (
        <form>
            <Grid container spacing={3}
                sx={{
                    '& .MuiInputBase-input': {
                        p: '7px 10px',
                        bgcolor: '#F9FAFB', 
                        border: '1px solid #F9FAFB'
                    }
                }}
            >
                <Grid item xs={12} md={6}
                    sx={{display: 'grid'}}
                >
                    <CmLabel htmlFor="name">Nombre*</CmLabel>
                    <TextField
                        name="name"
                        type="text"
                        placeholder="Introduce..."
                        value={formData.name}
                        onChange={handleChange}
                        sx={{
                            p: 0
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={6}
                    sx={{display: 'grid'}}
                >
                    <CmLabel htmlFor="lastName">Apellidos*</CmLabel>
                    <TextField
                        name="lastName"
                        type="text"
                        placeholder="Introduce..."
                        value={formData.lastName}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={6}
                    sx={{display: 'grid'}}
                >
                    <CmLabel htmlFor="email">Email*</CmLabel>
                    <TextField
                        name="email"
                        type="email"
                        placeholder="Introduce..."
                        value={formData.email}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={6}
                    sx={{display: 'grid'}}
                >
                    <CmLabel htmlFor="confirmEmail">Confirmar email*</CmLabel>
                    <TextField
                        name="confirmEmail"
                        type="email"
                        placeholder="Introduce..."
                        value={formData.confirmEmail}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={12}
                    sx={{display: 'grid'}}
                >
                    <CmLabel htmlFor="passport">Pasaporte*</CmLabel>
                    <TextField
                        name="passport"
                        type="text"
                        placeholder="Introduce..."
                        value={formData.passport}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={12}
                    sx={{display: 'grid'}}
                >
                    <CmLabel htmlFor="phone">Telefono*</CmLabel>
                    <TextField
                        name="phone"
                        type="text"
                        placeholder="Introduce..."
                        value={formData.phone}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
        </form>
    )
}

export default FormTitular;