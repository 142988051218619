import {
    Avatar,
    Box,
    Card,
    CardContent,
    Rating,
    Typography
} from '@mui/material'
import React from 'react'
import trimStringWithEllipsis from '../../utils/trimLongString'

const ReviewCard = ({ card, positionCard, zoomCard }) => {
    var { nameCharacters, rating, title, text, date } = card

    return (
        <Card
            sx={{
                position: 'absolute',
                overflow: 'visible',
                height: { xs: 240, md: 350 },
                width: 330,
                left: positionCard,
                transform: `scale(${zoomCard})`,
                transition: 'left 1s, transform 1s',
                borderRadius: '10px'
            }}>
            <Box
                sx={{
                    position: 'relative',
                    left: '45%',
                    top: '-20px',
                    width: 'fit-content'
                }}>
                <Avatar
                    sx={{
                        bgcolor: '#40C5E8',
                        fontSize: '15px',
                        fontWeight: 700
                    }}>
                    {nameCharacters}
                </Avatar>
            </Box>
            <CardContent
                sx={{
                    py: '0px'
                }}>
                <Typography
                    fontFamily={'Poppins'}
                    color="secondary"
                    gutterBottom
                    component="div"
                    sx={{
                        textAlign: 'center',
                        fontSize: { xs: 18, md: 20 },
                        fontWeight: { xs: 600, md: 700 },
                        '&.MuiTypography-noWrap': {
                            overflow: 'ellipsis'
                        }
                    }}>
                    {title}
                </Typography>

                <Typography
                    variant="textBody"
                    sx={{
                        textAlign: 'center',
                        fontFamily: 'Inter'
                    }}>
                    {trimStringWithEllipsis(text)}
                </Typography>

                <Box
                    sx={{
                        textAlign: '-webkit-center',
                        mb: '10px'
                    }}>
                    <Box
                        sx={{
                            background: '#F0F0F1',
                            mt: '10px',
                            width: 'fit-content',
                            height: '20px',
                            px: '5px',
                            borderRadius: '12px',
                            lineHeight: 0.75
                        }}>
                        <Rating
                            name="read-only"
                            value={rating}
                            max={rating}
                            size="small"
                            readOnly
                        />
                    </Box>
                </Box>
                <Typography
                    variant="body2"
                    color="secondary"
                    sx={{
                        textAlign: 'center',
                        fontWeight: 400,
                        lineHeight: '24px'
                    }}>
                    {date}
                </Typography>
            </CardContent>
        </Card>
    )
}

export default ReviewCard
