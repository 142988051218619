import React from "react"
import {
    Breadcrumbs as MaterialBreadcrumbs,
    Link,
    Typography
} from "@mui/material"
import { NavigateNext } from "@mui/icons-material"
import { ReactComponent as HomeICon } from "../../assets/icons/house.svg"

const Breadcrumbs = ({ breadcrumbs }) => {
    if (!breadcrumbs?.length) return null
    return (
        <MaterialBreadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNext sx={{ color: "BFBFBF", mx: "4px" }} fontSize="small" />}
            sx={{
                py: "2rem"
            }}
        >
            <Link
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
                color="primary"
                href="/"
            >
                <HomeICon fontSize="inherit" />
            </Link>
            {
                breadcrumbs.map((breadcrumb, index) => {
                    const isLast = index === breadcrumbs.length - 1
                    return (
                        <Link
                            key={breadcrumb?.name}
                            underline={isLast ? "none" : "hover"}
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            href={isLast ? "#" : breadcrumb.path}
                        >
                            <Typography
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color={isLast ? "secondary" : "primary"}
                            >
                                {breadcrumb?.name}
                            </Typography>
                        </Link>
                    )
                })
            }
        </MaterialBreadcrumbs>
    )
}

export default Breadcrumbs
