export const cards = [
    {
        name: "Italia",
        cities: "12",
        activities: 10000,
        path: "destinos/italia",
        image: require("../../assets/images/rome.png")
    },
     {
        name: "Francia",
        cities: "11",
        activities: 90000,
        path: "destinos/francia",
        image: require("../../assets/images/paris.png")
    },
      {
        name: "España",
        cities: "13",
        activities: 12000,
        path: "destinos/espana",
        image: require("../../assets/images/la-coruna.png")
    },
    {
        name: "Suiza",
        cities: "12",
        activities: 10000,
        path: "destinos/Suiza",
        image: require("../../assets/images/prague.png")
    },
     {
        name: "Portugal",
        cities: "11",
        activities: 90000,
        path: "destinos/postugal",
        image: require("../../assets/images/granada.png")
    },
      {
        name: "Marruecos",
        cities: "13",
        activities: 12000,
          path: "destinos/Marruecos",
        image: require("../../assets/images/bangkok.png")
    },
      {
        name: "Italia",
        cities: "12",
        activities: 10000,
        path: "destinos/italia",
        image: require("../../assets/images/rome.png")
    },
     {
        name: "Francia",
        cities: "11",
        activities: 90000,
        path: "destinos/francia",
        image: require("../../assets/images/paris.png")
    },
      {
        name: "España",
        cities: "13",
        activities: 12000,
        path: "destinos/espana",
        image: require("../../assets/images/la-coruna.png")
    },
    {
        name: "Suiza",
        cities: "12",
        activities: 10000,
        path: "destinos/Suiza",
        image: require("../../assets/images/prague.png")
    },
     {
        name: "Portugal",
        cities: "11",
        activities: 90000,
        path: "destinos/postugal",
        image: require("../../assets/images/granada.png")
    },
      {
        name: "Marruecos",
        cities: "13",
        activities: 12000,
          path: "destinos/Marruecos",
        image: require("../../assets/images/bangkok.png")
    },
      {
        name: "Italia",
        cities: "12",
        activities: 10000,
        path: "destinos/italia",
        image: require("../../assets/images/rome.png")
    },
     {
        name: "Francia",
        cities: "11",
        activities: 90000,
        path: "destinos/francia",
        image: require("../../assets/images/paris.png")
    },
      {
        name: "España",
        cities: "13",
        activities: 12000,
        path: "destinos/espana",
        image: require("../../assets/images/la-coruna.png")
    },
    {
        name: "Suiza",
        cities: "12",
        activities: 10000,
        path: "destinos/Suiza",
        image: require("../../assets/images/prague.png")
    },
]