import React from "react"
import { Box, Typography } from "@mui/material"
import { InfoOutlined } from "@mui/icons-material"

const AdditionalInformationSection = ({ sections }) => {
    if (!sections.length) return null
    return (
        <Box sx={{
            display: { xs: "none", md: "block" },
            mt: "3rem",
        }}>
            <Typography
                color="secondary"
                component="h2"
                sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    mb: "1rem",
                }}
                variant="h5"
            >
                Información adicional del operador <InfoOutlined />
            </Typography>
            {
                sections.map((section, index) => (
                    <Box
                        key={`${section.title}${index}`}
                        sx={{
                            borderBottom: "1px solid #666B6E",
                            pb: "1rem",
                            mt: "1rem",
                        }}
                    >
                        <Typography color="secondary" sx={{ mb: ".8rem" }}>
                            {section.title}
                        </Typography>
                        <Typography color="grayText" fontFamily="Inter">
                            {section.description}
                        </Typography>
                    </Box>
                ))
            }
        </Box>
    )
}

export default AdditionalInformationSection
