import React from "react"

const RichText = ({ text, styles = {} }) => (
    <div
        dangerouslySetInnerHTML={{
            __html: `<p>${text}</p>`
        }}
        style={styles}
    />
)

export default RichText
