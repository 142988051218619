import React from 'react'
import { Box, Container, Typography } from '@mui/material'
import bgImg from '../../assets/images/young-traveler.jpg'
import TagButton from '../TagButton'

const StaticPagesWrapper = ({ children, title = '' }) => {
    return (
        <>
            <Container>
                {title ? (
                    <Typography
                        color='secondary'
                        mt={6}
                        mb={3}
                        variant='h1'
                    >
                            {title}
                        </Typography>
                    ) : null
                }
                {children}
            
            </Container>
            <Box
                sx={{
                    alignItems: 'center',
                    background: `
                        linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
                        url(${bgImg})
                    `,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    display: { xs: 'none', md: 'flex' },
                    flexDirection: 'column',
                    justifyContent: 'center',
                    py: '5rem',
                }}
            >
                <Typography
                    color='primary.contrastText'
                    mb={1}
                >
                    Contacto
                </Typography>
                <Typography
                    color='primary.contrastText'
                    variant='title'
                    mb={4}
                >
                    ¿Necesitas ayuda?
                </Typography>
                <TagButton
                    text='Envíanos un correo'
                    href='mailto:reservas@trekzy.com'
                    sx={{
                        borderRadius: 10,
                        fontSize: 18,
                        fontFamily: 'Poppins',
                        fontWeight: 600,
                        py: '.5rem',
                        px: '1.2rem'
                    }}
                />
            </Box>
        </>
    )
}

export default StaticPagesWrapper
