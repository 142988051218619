import React from "react"
import { Link } from '@mui/material'
import "./imagecard.css"

const ImageCard = ({ imageUrl, alt, hover }) => {
    const hoverEffectStar = `star-svg ${hover ? "onhover" : ""}`
    const hoverScale = `stars-container ${hover ? "onhover" : ""}`
    const hoverSubtitle = `subtitle ${hover ? "onhover" : ""}`

    return (
        <div className="card">
            <div className="rating">
                <div className={hoverScale}>
                    <span className={hoverEffectStar}>&#9733;</span>
                    <span className={hoverEffectStar}>&#9733;</span>
                    <span className={hoverEffectStar}>&#9733;</span>
                    <span className={hoverEffectStar}>&#9733;</span>
                    <span className={hoverEffectStar}>&#9733;</span>
                </div>
            </div>
            <div className={hoverSubtitle}>
                <Link
                    underline="none"
                    href={`/destino/${alt}`}
                    color="primary.contrastText"
                >
                    <h1>
                        {alt}
                    </h1>
                </Link>
            </div>
            <img src={imageUrl} alt={alt} className="card-image" />
            <div className="card-overlay"></div>
        </div>
    )
}

export default ImageCard
