import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material'
import { CarouselProvider, DotGroup, Slider, Slide } from 'pure-react-carousel'

import BlurImageWrapper, { opacities } from '../../BlurImageWrapper'
import AvatarCard from '../../AvatarCard'
import { useScreenDetector } from '../../../hooks/useScreenDetector'

import trekzyHeroLight from '../../../assets/images/bg-light-t-2.png'

const DesktopTeamSection = ({ children }) => (
    <BlurImageWrapper imageUrl={trekzyHeroLight} opacity={opacities.none}>
        <Container
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                py: '4rem'
            }}>
            <Typography
                color="secondary.main"
                variant="h2"
                sx={{ mb: '1rem', fontWeight: 'bold' }}>
                Nuestro equipo
            </Typography>
            <Typography
                variant="body1"
                color="graytext.main"
                sx={{
                    textAlign: 'center',
                    maxWidth: 1024
                }}>
                Conoce a nuestro equipo humano
            </Typography>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    columnGap: '3rem',
                    mt: '1rem'
                }}>
                {children}
            </Box>
        </Container>
    </BlurImageWrapper>
)

const MobileTeamSection = ({ children }) => (
    <Box>
        <Container
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                mb: '2rem'
            }}>
            <Typography
                color="secondary.main"
                variant="subtitle"
                sx={{ mb: '1rem', fontWeight: 'bold' }}>
                Nuestro equipo
            </Typography>
            <Typography
                variant="body1"
                color="graytext.main"
                sx={{
                    textAlign: 'center',
                    maxWidth: 1024
                }}>
                Conoce a nuestro equipo humano
            </Typography>
        </Container>
        <CarouselProvider
            naturalSlideWidth={120}
            naturalSlideHeight={125}
            totalSlides={children.length}
            visibleSlides={1}
            currentSlide={Math.floor(children.length / 2) - 1}>
            <Slider>
                {children.map((Child, index) => (
                    <Slide index={index} key={index}>
                        {Child}
                    </Slide>
                ))}
            </Slider>
        </CarouselProvider>
    </Box>
)

const TeamSection = () => {
    const { isMobile } = useScreenDetector()
    const Wrapper = isMobile ? MobileTeamSection : DesktopTeamSection
    return (
        <Wrapper>
            <AvatarCard
                imageUrl="Soraya.jpg"
                height={isMobile ? '200px' : '300px'}
                width={isMobile ? '180px' : '300px'}
                avatarName="Soraya Plaza"
                title="Proyectos"
            />
            <AvatarCard
                imageUrl="Jon.jpg"
                height={isMobile ? '200px' : '300px'}
                width={isMobile ? '180px' : '300px'}
                avatarName="Jon Gomara"
                title="Marketing"
            />
            <AvatarCard
                imageUrl="Maria.jpg"
                height={isMobile ? '200px' : '300px'}
                width={isMobile ? '180px' : '300px'}
                avatarName="María Cordero"
                title="Recursos Humanos"
            />
            <AvatarCard
                imageUrl="Valeria.jpg"
                height={isMobile ? '200px' : '300px'}
                width={isMobile ? '180px' : '300px'}
                avatarName="Valeria de Magistris"
                title="Operación"
            />
            <AvatarCard
                imageUrl="Aaron.jpeg"
                height={isMobile ? '200px' : '300px'}
                width={isMobile ? '180px' : '300px'}
                avatarName="Aaron del Río"
                title="Administración"
            />
            <AvatarCard
                imageUrl="Flor.jpg"
                height={isMobile ? '200px' : '300px'}
                width={isMobile ? '180px' : '300px'}
                avatarName="Flor Carbajal"
                title="Proveedores"
            />
        </Wrapper>
    )
}

export default TeamSection
