import React from "react";

import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Typography
} from "@mui/material";
import ProductCard2 from "../ProductCard2";

const CartModal = ({cartTours, total = 300 , iva = 25}) => {
    return (
        <Card variant="outlined"
            sx={{
                width: '450px',
                position: 'absolute',
                zIndex: '100',
                backgroundColor: '#FFFFFF',
                right: { md: '6%', lg: '10%' },
                mt: '2px'
            }}
        >
            <CardContent sx={{
                pb: '8px'
            }}>
                {cartTours.map(item => (
                    <ProductCard2 key={item.id} data={item} isCartModal={true}/>
                ))}
            </CardContent>
            <CardActions
                sx={{
                    px: '22px',
                    py: '20px',
                    display: 'block',
                    backgroundColor: '#F0F0F1'
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '14px',
                            color: '#223F7F'
                        }}
                    >
                        Total:
                    </Typography>
                    <Box>
                        <Typography
                            sx={{
                                fontSize: { xs: '14px', md: '18px' },
                                fontWeight: { xs: 600, md: 700 },
                                color: '#223F7F'
                            }}>
                            {`${total} €`}
                        </Typography>
                        <Typography
                            color="grayText"
                            sx={{
                                textAlign: 'right',
                                fontSize: { xs: '10px', md: '12px' },
                                fontWeight: 400
                            }}>
                            {`${iva}€ IVA`}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <Button
                        href="/checkout"
                        variant="contained"
                    >Ir al carrito</Button>
                </Box>
            </CardActions>
        </Card>
    )
}

export default CartModal;