import React from "react";

import { useSelector } from "react-redux";
import { selectTours } from "../../../app/features/cartSlice";
import { recomendedTours } from "../../../utils/data";
import Resumen from "./resumen";
import Recomendations from "./recomendations";
import { useScreenDetector } from "../../../hooks/useScreenDetector";

const Step1 = ({ handleNext }) => {
    const {isMobile} = useScreenDetector()
    const cartTours = useSelector(selectTours);
    return (
        <>
            <Resumen handleNext={handleNext} />
            {!isMobile && <Recomendations recomendedTours={recomendedTours} />}
        </>
    )
}

export default Step1;