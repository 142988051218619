import React from "react"
import { Box, Container, Typography } from "@mui/material"
import {
    CarouselProvider,
    DotGroup,
    Slider,
    Slide,
} from "pure-react-carousel";
import DestinationCard from "../DestinationCard"
import { useScreenDetector } from "../../hooks/useScreenDetector"

import "pure-react-carousel/dist/react-carousel.es.css";
import "./styles.css"

const DestinationGallery = ({ title, destinations, textPosition = "end"}) => {
    const {isMobile} = useScreenDetector();
    if (!destinations.length) return null;

    return (
        <Box sx={{ mb: "4rem" }}>
            <Container sx={{
                textAlign: { xs: "center", md: "left" },
                mb: "2rem"
            }}>
                <Typography color="secondary" variant="subtitle">
                    {title}
                </Typography>
            </Container>
            {
                isMobile ? (
                    <div className="carousel__container">
                        <CarouselProvider
                            naturalSlideWidth={100}
                            naturalSlideHeight={125}
                            totalSlides={destinations.length}
                            visibleSlides={1}
                            currentSlide={Math.floor(destinations.length / 2) - 1}
                        >
                            <Slider>
                                {destinations.map((destination, index) => (
                                    <Slide
                                        index={index}
                                        key={`${destination.title}${index}`}
                                    >
                                        <DestinationCard {...destination} textPosition={textPosition} />
                                    </Slide>
                                ))}
                            </Slider>
                            <div className="navigation-dots">
                                <DotGroup />
                            </div>
                        </CarouselProvider>
                    </div>
                ): (
                     <ul className="desktop-carousel">
                        {destinations.map((destination, index) => (
                            <li
                                key={`${destination.title}${index}`}
                                style={{
                                    marginRight: "1rem"
                                }}>
                                <DestinationCard {...destination} textPosition={textPosition} />
                            </li>
                        ))}
                    </ul>   
                )
            }
        </Box>
    )
}

export default DestinationGallery
