import { Container, Grid, Typography } from '@mui/material'
import Carousel from 'react-material-ui-carousel'
import IconCard from '../IconCard'

import Coins from '../../assets/images/coins.png'
import Users from '../../assets/images/users.png'
import Cancel from '../../assets/images/cancel.png'
import Edit from '../../assets/images/edit.png'

const cards = [
    {
        title: 'Mejores precios',
        text: 'Trabajamos solamente con el precio final de cada actividad',
        imgSource: Coins,
        altText: 'tours privados al mejor precio'
    },
    {
        title: 'Atención personalizada',
        text: 'Atendemos tus dudas con un equipo especializado y dedicado para ti',
        imgSource: Users,
        altText: 'tours privados con la mejor atención al cliente'
    },
    {
        title: 'Cancelación gratuita',
        text: 'Te devolvemos el 100% si cancelas 48h antes del inicio de la actividad que has contratado',
        imgSource: Cancel,
        altText: 'Tours privados con cancelación gratuita'
    },
    {
        title: 'Personalización',
        text: 'Los tours privados son 100% customizables',
        imgSource: Edit,
        altText: 'Tours privados personalizados a medida'
    }
]

const CarouselIconCards = () => {
    return (
        <>
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: '3rem',
                    mb: '1rem'
                }}>
                <Typography color="secondary" variant="subtitle" fontWeight={700}>
                    ¿Por qué elegir a Trekzy?
                </Typography>
            </Container>
            <Carousel
                autoPlay={false}
                sx={{ my: '20px', display: { xs: 'block', md: 'none' } }}>
                {cards.map((card) => (
                    <IconCard key={card.title} card={card} />
                ))}
            </Carousel>
            <Grid
                spacing={1}
                container
                sx={{ my: '20px', display: { xs: 'none', md: 'flex' } }}
            >
                {cards.map((card) => (
                    <Grid key={card.title} item xs={3} sx={{ height: "fit-content"}}>
                        <IconCard card={card} />
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

export default CarouselIconCards
