/* eslint-disable no-undef */
export const pages = [
    {
        text: 'Destinos',
        url: '/destinos'
    },
    {
        text: 'Guías de viaje',
        url: '/guias-de-viaje'
    },
    {
        text: 'Blogs de viaje',
        url: '/blogs'
    }
]

export const gridImagesContent = {
    title: 'Elige los destinos favoritos',
    text: 'Basado en las opiniones de otros clientes',
    itemData: [
        {
            img: require('../assets/images/granada.png'),
            title: 'Granada',
            cols: 1,
            rating: 3
        },
        {
            img: require('../assets/images/bangkok.png'),
            title: 'Bangkok',
            cols: 1,
            rating: 4
        },
        {
            img: require('../assets/images/new-york.png'),
            title: 'Nueva York',
            cols: 2,
            rating: 5
        },
        {
            img: require('../assets/images/rome.png'),
            title: 'Roma',
            cols: 1,
            rating: 3
        },
        {
            img: require('../assets/images/santiago.png'),
            title: 'Santiago',
            cols: 2,
            rating: 5
        },
        {
            img: require('../assets/images/prague.png'),
            title: 'Praga',
            cols: 1,
            rating: 4
        }
    ]
}

export const destinations = [
    {
        img: require('../assets/images/granada.png'),
        title: 'Granada',
        url: 'guias-de-viaje/espana/granada'
    },
    {
        img: require('../assets/images/bangkok.png'),
        title: 'Bangkok',
        url: 'guias-de-viaje/tailandia/bangkok'
    },
    {
        img: require('../assets/images/new-york.png'),
        title: 'Nueva York',
        url: 'guias-de-viaje/eua/new-york'
    },
    {
        img: require('../assets/images/rome.png'),
        title: 'Roma',
        url: 'guias-de-viaje/italia/rome'
    },
    {
        img: require('../assets/images/santiago.png'),
        title: 'Santiago',
        url: 'guias-de-viaje/chile/santiago'
    },
    {
        img: require('../assets/images/prague.png'),
        title: 'Praga',
        url: 'guias-de-viaje/replublica-checa/prague'
    }
]

export const blogDestinations = [
    {
        img: require('../assets/images/granada.png'),
        title: 'Qué hacer en pareja en Granada',
        url: '/guias-de-viaje/espana/granada/que-hacer-en-pareja'
    },
    {
        img: require('../assets/images/bangkok.png'),
        title: 'Qué hacer en pareja en Granada',
        url: '/guias-de-viaje/espana/granada/que-hacer-en-pareja'
    },
    {
        img: require('../assets/images/new-york.png'),
        title: 'Qué hacer en pareja en Granada',
        url: '/guias-de-viaje/espana/granada/que-hacer-en-pareja'
    },
    {
        img: require('../assets/images/rome.png'),
        title: 'Qué hacer en pareja en Granada',
        url: '/guias-de-viaje/espana/granada/que-hacer-en-pareja'
    },
    {
        img: require('../assets/images/santiago.png'),
        title: 'Qué hacer en pareja en Granada',
        url: '/guias-de-viaje/espana/granada/que-hacer-en-pareja'
    },
    {
        img: require('../assets/images/prague.png'),
        title: 'Qué hacer en pareja en Granada',
        url: '/guias-de-viaje/espana/granada/que-hacer-en-pareja'
    }
]

const mountainTours = [
    {
        imageUrl: 'images/apalaches.png',
        ivaIncluded: true,
        location: 'Georgia, Estados Unidos',
        path: '/destinos/eua/georgia/sendero-apalaches',
        price: 150.5,
        rate: 9.2,
        reviews: {
            state: 'Notable',
            number: 253
        },
        title: 'Sendero de los Apalaches'
    },
    {
        imageUrl: 'images/fimmvorouhals.png',
        ivaIncluded: true,
        location: 'Islandia',
        path: '/destinos/islandia/fimmvorouhals/paso-fimmvorouhals',
        price: 150.5,
        rate: 8.9,
        reviews: {
            state: 'Notable',
            number: 253
        },
        title: 'Paso de Fimmvörðuháls'
    },
    {
        imageUrl: 'images/kalalau.png',
        ivaIncluded: true,
        location: 'Hawái, Estados Unidos',
        price: 150.5,
        path: '/destinos/eua/hawai/sendero-kalalau-gastronomia',
        rate: 9,
        reviews: {
            state: 'Excelente',
            number: 9378
        },
        title: 'Sendero Kalalau y gastronomía local'
    }
]

const cityTours = [
    {
        imageUrl: 'images/apalaches.png',
        ivaIncluded: true,
        location: 'Estambul, Turquía',
        path: '/destinos/turquia/estambul/estambul-nocturno-sofia-mezquita-azul',
        price: 150.5,
        rate: 7.4,
        reviews: {
            state: 'Notable',
            number: 253
        },
        title: 'Estambul nocturno: Sofía y la Mezquita Azul'
    },
    {
        imageUrl: 'images/fimmvorouhals.png',
        ivaIncluded: true,
        location: 'Fez, Marruecos',
        path: '/destinos/marruecos/Fez/mercado-especias',
        price: 150.5,
        rate: 8.9,
        reviews: {
            state: 'Notable',
            number: 253
        },
        title: 'Descubre el mercado de especias en Fez'
    },
    {
        imageUrl: 'images/kalalau.png',
        ivaIncluded: true,
        location: 'París, Francia',
        path: '/destinos/francia/paris/museos-paris',
        price: 150.5,
        rate: 9,
        reviews: {
            state: 'Excelente',
            number: 9378
        },
        title: 'Grandes museos de París: Pintura y antigüedad'
    }
]

export const dataCarousel = {
    title1: 'Testimonios',
    title2: 'Lo que opinan nuestros clientes',
    text: `Nuestro mayor activo es la satisfacción de nuestros clientes.`,
    reviews: [
        {
            nameCharacters: 'AS',
            rating: 5,
            title: 'Museo del Prado Private Guided Tour',
            text: 'Fantastic tour option! Our guides were and so knowledgeable! Made the entire tour interesting and learned so much throughout. Highly recommend!',
            date: '28/03/2024'
        },
        {
            nameCharacters: 'DS',
            rating: 4,
            title: 'Park Guell and Sagrada Familia Private Tour',
            text: `Best tour guide in Barcelona I had an incredible experience with my tour guide
in Barcelona. Her extensive knowledge brought the city to life, making every
detail fascinating. The tour was not only informative but also enjoyable, creating
lasting memories. The personalized, private tour added a special touch, and I
highly recommend her to anyone visiting Barcelona.`,
            date: '02/02/2024'
        },
        {
            nameCharacters: 'FS',
            rating: 4,
            title: 'Alcazar and Cathedral of Seville Private Tour',
            text: `Excelente experiencia!! Excelente visita. Nosso guia Hector nos deu muitas
informações interessantes demonstrando ter muito conhecimento da história da
cidade.`,
            date: '03/05/2024'
        },
        {
            nameCharacters: 'HS',
            rating: 5,
            title: 'Madrid Royal Palace Private Tour',
            text: `The tour was very nice the guide gave thorough information with the tour and he
was very kind. Excellent tour guide Robin/Ruben.`,
            date: '03/04/2024'
        },
        {
            nameCharacters: 'GS',
            rating: 4,
            title: 'Chapultepec Castle and Anthropology Museum Tour',
            text: `The Anthropology Museum was amazing, even for me—someone with very little
background in the indigenous cultures of Mexico. That&#39;s due in large part to our
guide, Emanuel, who helped piece together thousands of years of history in a
three-hour tour. He led us through three rooms covering the Mexica; the

Zapotec and Mixtec of Oaxaca; and the Maya. Most rooms include signage in
Spanish and English, but individual items are often only described in Spanish.
Thanks to our guide, I came out knowing more about the cultures of
Mesoamerica. I definitely want to explore other parts of the museum on my next
visit. After the museum, we walked to the Chapultepec Castle, where the
highlights included some interesting murals chronicling Mexican history from the
19th and early 20th centuries and a great view of the city. Thanks again,
Emanuel!`,
            date: '26/05/2024'
        },
        {
            nameCharacters: 'KS',
            rating: 5,
            title: 'Private Full-Day Teotihuacan Archaeological Tour:',
            text: `We enjoyed our trip with Manuel and Abraham to the Teotihuacan. Manuel was
our guide. Abraham was our driver. Manuel is very knowledgeable. We asked
him many questions and enjoyed learning from him. He has a degree in Mayan
linguistics and is generally interested in history. He participates in seminars to
increase and update his understanding of the current archaeological findings. If
you go alone and rely upon the signage, you&#39;ll be learning dated information as
the signage hasn&#39;t been revised since the 1970&#39;s. He has a good command of
the English language.`,
            date: '03/01/2024'
        }
    ],
    footer: {
        title: '¿Necesitas ayuda?',
        text: 'Cuenta con nuestro equipo todos los días de la semana, de lunes a domingo.',
        buttonLabel: 'Contacta con Trekzy',
        buttonUrl: '#'
    }
}

export const getCardsByTours = (name) => {
    switch (name) {
        case 'Montaña':
            return [...mountainTours, ...mountainTours]
        case 'Ciudad':
            return [...cityTours, ...cityTours]
        default:
            return [...mountainTours, ...cityTours]
    }
}

export const recomendedTours = [
    {
        imageUrl: 'images/kalalau.png',
        ivaIncluded: true,
        languages: null,
        time: null,
        cancelable: false,
        rate: 8.2,
        price: 150.5,
        reviews: {
            state: 'Excelente',
            number: 9378
        },
        description: `Lorem ipsum dolor sit amet consectetur. Aliquet amet in proin nibh. 
            Habitasse metus quis at est platea sem venenatis pellentesque. Commodo velit nisl
            diam magnis massa volutpa.`,
        title: 'Sendero Kalalau y gastronomía local',
        date: '10 de junio de 2024',
        type: 'Paquete premium(150€)',
        assistants: '1 adulto, 3 ninos',
        moreDetails: false,
        edit: true,
        remove: true
    },
    {
        imageUrl: 'images/kalalau.png',
        ivaIncluded: true,
        languages: 'Ingles',
        time: 'De 4 a 5 horas',
        cancelable: true,
        rate: 9,
        price: 150.5,
        reviews: {
            state: 'Excelente',
            number: 9378
        },
        description: `Lorem ipsum dolor sit amet consectetur. Aliquet amet in proin nibh. 
            Habitasse metus quis at est platea sem venenatis pellentesque. Commodo velit nisl
            diam magnis massa volutpa.`,
        title: 'Sendero Kalalau y gastronomía local',
        moreDetails: true,
        edit: false,
        remove: false
    },
    {
        imageUrl: 'images/kalalau.png',
        ivaIncluded: true,
        languages: 'Ingles y Espanol',
        time: 'De 4 a 5 horas',
        cancelable: true,
        rate: 9,
        price: 150.5,
        reviews: {
            state: 'Excelente',
            number: 9378
        },
        description: `Lorem ipsum dolor sit amet consectetur. Aliquet amet in proin nibh. 
            Habitasse metus quis at est platea sem venenatis pellentesque. Commodo velit nisl
            diam magnis massa volutpa.`,
        title: 'Sendero Kalalau y gastronomía local',
        moreDetails: true,
        edit: false,
        remove: false
    }
]

export const predictions = {
    destinos: [
        {
            name: 'Madrid',
            path: '/destinos/espana/madrid'
        },
        {
            name: 'Madagascar',
            path: '/destinos/australia/madagascar'
        },
        {
            name: 'Paris',
            path: '/destinos/francia/paris'
        },
        {
            name: 'Barcelona',
            path: '/destinos/espana/barcelona'
        }
    ],
    tours: [
        {
            name: 'Museos Madrid',
            path: '/destinos/espana/madrid/museos-madrid'
        },
        {
            name: 'Snorkel Madagascar',
            path: '/destinos/australia/madagascar/snorkel-madagascar'
        },
        {
            name: 'Museos Paris',
            path: '/destinos/francia/paris/museos-paris'
        },
        {
            name: 'Museos Barcelona',
            path: '/destinos/espana/barcelona/museos-barcelona'
        }
    ]
}

export const guidesByRegion = [
    {
        name: 'Asia',
        data: []
    },
    {
        name: 'Europa',
        data: [
            {
                name: 'Italia',
                image: require('../assets/images/rome.png'),
                guides: [
                    {
                        name: 'Roma',
                        path: '/guias-de-viaje/italia/roma'
                    },
                    {
                        name: 'Milan',
                        path: '/guias-de-viaje/italia/milan'
                    },
                    {
                        name: 'Nápoles',
                        path: '/guias-de-viaje/italia/napoles'
                    },
                    {
                        name: 'Turín',
                        path: '/guias-de-viaje/italia/turin'
                    },
                    {
                        name: 'Palermo',
                        path: '/guias-de-viaje/italia/palermo'
                    },
                    {
                        name: 'Florencia',
                        path: '/guias-de-viaje/italia/florencia'
                    },
                    {
                        name: 'Bali',
                        path: '/guias-de-viaje/italia/bali'
                    },
                    {
                        name: 'Venecia',
                        path: '/guias-de-viaje/italia/venecia'
                    },
                    {
                        name: 'Verona',
                        path: '/guias-de-viaje/italia/verona'
                    },
                    {
                        name: 'Genoa',
                        path: '/guias-de-viaje/italia/genoa'
                    }
                ]
            },
            {
                name: 'Francia',
                image: require('../assets/images/paris.png'),
                guides: [
                    {
                        name: 'Marsella',
                        path: '/guias-de-viaje/francia/marsella'
                    },
                    {
                        name: 'París',
                        path: '/guias-de-viaje/francia/paris'
                    },
                    {
                        name: 'Lyon',
                        path: '/guias-de-viaje/francia/lyon'
                    },
                    {
                        name: 'Toulouse',
                        path: '/guias-de-viaje/francia/toulouse'
                    }
                ]
            },
            {
                name: 'España',
                image: require('../assets/images/la-coruna.png'),
                guides: [
                    {
                        name: 'La Coruña',
                        path: '/guias-de-viaje/espana/la-coruna'
                    },
                    {
                        name: 'Granada',
                        path: '/guias-de-viaje/espana/granada'
                    },
                    {
                        name: 'Málaga',
                        path: '/guias-de-viaje/espana/malaga'
                    },
                    {
                        name: 'Barcelona',
                        path: '/guias-de-viaje/espana/barcelona'
                    },
                    {
                        name: 'Galicia',
                        path: '/guias-de-viaje/espana/galicia'
                    },
                    {
                        name: 'Burgos',
                        path: '/guias-de-viaje/espana/burgos'
                    }
                ]
            }
        ]
    },
    {
        name: 'África',
        data: []
    },
    {
        name: 'América',
        data: [
            {
                name: 'México',
                image: require('../assets/images/la-coruna.png'),
                guides: [
                    {
                        name: 'Guadalajara',
                        path: '/guias-de-viaje/mexico/guadalajara'
                    },
                    {
                        name: 'Cancun',
                        path: '/guias-de-viaje/mexico/cancun'
                    },
                    {
                        name: 'Puerto Vallarta',
                        path: '/guias-de-viaje/espana/pto-vallarta'
                    }
                ]
            }
        ]
    },
    {
        name: 'Oceanía',
        data: []
    }
]

export const getGuidesPredictions = (value) => {
    const flatGuides = guidesByRegion
        .map((element) => element.data.map((country) => country.guides))
        .filter(Boolean)
        .reduce((a, b) => [...a, ...b])
        .flat()

    const filteredGuides = flatGuides.filter((guide) =>
        guide.name.toLowerCase().includes(value.toLowerCase())
    )

    return {
        'Guías de viaje': filteredGuides
    }
}

export const getMockSuggestions = (value) => {
    let sugerencias = []
    const destinos = predictions.destinos.filter((destino) =>
        destino.name.toLowerCase().includes(value.toLowerCase())
    )
    const tours = predictions.tours.filter((tour) =>
        tour.name.toLowerCase().includes(value.toLowerCase())
    )

    if (!destinos.length && !tours.length && value.length > 1) {
        const _destinos = predictions.destinos.filter((destino) =>
            destino.name.toLowerCase().includes(value[0].toLowerCase())
        )
        const _tours = predictions.tours.filter((tour) =>
            tour.name.toLowerCase().includes(value[0].toLowerCase())
        )

        sugerencias = [..._destinos, ..._tours]
    }

    return {
        Destinos: destinos,
        Tours: tours,
        sugerencias
    }
}
