import React from "react"
import {
    Box,
    FormControl,
    Input as InlineInput,
    InputAdornment,
    OutlinedInput,
} from "@mui/material"
import PredictionsList from "../PredictionsList"
import { useClickOutsideDetector } from "../../hooks/useClickOutsideDetector"
import { ReactComponent as SearchIconFill } from "../../assets/icons/search-fill.svg"
import { getMockSuggestions, getGuidesPredictions } from "../../utils/data"

const SearchInput = ({
    placeHolder = "Busca un destino o tour",
    styles = {},
    source = "",
    SearchIcon = SearchIconFill,
    variant = "outlined"
}) => {
    const [value, setValue] = React.useState("")
    const [results, setResults] = React.useState({})
    const [openModal, setOpenModal] = React.useState(false);
    const wrapperRef = React.useRef(null);

    const inputVariants = {
        inline: InlineInput,
        outlined: OutlinedInput
    }
    const Input = inputVariants[variant];

    useClickOutsideDetector(wrapperRef, () => {
        setOpenModal(false);
    })

    const handleOnChange = (evt) => {
        const { value } = evt.target;
        setValue(value)
        if (!value) {
            setOpenModal(false);
        } else {
            setOpenModal(true)
            const suggestions = source === "guides" ?
                getGuidesPredictions(value) :
                getMockSuggestions(value)
            setResults(suggestions)
        }
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexWrap: "wrap",
                maxWidth: 650,
                width: "100%",
                ...styles
            }}>
            <FormControl ref={wrapperRef} sx={{ m: 1, width: "100%", position: "relative"}}>
                <Input
                    color="primary"
                    placeholder={placeHolder}
                    type={"text"}
                    sx={{
                        borderRadius: 30,
                        paddingLeft: 1,
                        paddingRight: variant === "outlined" ? 2 : 1,
                        backgroundColor: "#ffffff",
                        input: {
                            "&::placeholder": {
                                opacity: 0.8,
                                color: variant === "outlined" ? "#666B6E" : "#40C5E8"
                            },
                        },
                    }}
                    endAdornment={
                        <InputAdornment position="end">
                            <SearchIcon style={{
                                width: variant === "outlined" ? 36 : 30,
                                height: variant === "outlined" ? 36 : 30
                            }} />
                        </InputAdornment>
                    }
                    onChange={handleOnChange}
                />
                {openModal ? (
                    <Box sx={{
                        backgroundColor: "#ffffff",
                        borderRadius: 3,
                        boxShadow: 10,
                        float: "bottom",
                        maxHeight: "20rem",
                        mt: variant === "outlined" ? "4rem" : "2.5rem",
                        position: "absolute",
                        overflowY: "scroll",
                        width: "100%",
                        zIndex: 10
                    }}>
                        <PredictionsList data={results} value={value} />
                    </Box>
                ) : null}
            </FormControl>
        </Box>
    )
}

export default SearchInput
