import React, { useState } from "react";

import { Box, FormControlLabel, MenuItem, Select, Typography } from "@mui/material";
import BpRadio from "./BpRadio";

const CmLabel = (props) => {
    const { children, ...lProps } = props;
    return (
        <label style={{color: '#6D7280', fontSize: '14px'}} {...lProps}>{children}</label>
    );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const guideTypes = [
    'Sin guia',
    'Con guia',
];

const vehicles = [
    'Vehiculo 1',
    'Vehiculo 2'
]

const atractions = [
    'Actraction 1',
    'Atraction 2 2'
]

const Step2 = () => {
    const [atraction, setAtraction] = useState('Actraction 1')
    const [vehicle, setVehicle] = useState('Vehiculo 1')
    const [guide, setGuide] = useState('Sin guia')
    const [complement1, setComplement1] = useState(false)
    const [complement2, setComplement2] = useState(false)

    const handleChangeAtraction = (event) => {
        setAtraction(event.target.value);
    }

    const handleChangeVehicle = (event) => {
        setVehicle(event.target.value);
    }

    const handleChangeGuide = (event) => {
        setGuide(event.target.value);
    }

    const handleComplement1 = () => {
        console.log('click complement 1');
        setComplement1(!complement1);
    }

    const handleComplement2 = () => {
        setComplement2(!complement2);
    }

    return (
        <>
            <Typography
                variant="h5"
                sx={{
                    fontSize: '16px',
                    color: 'secondary.main'
                }}
            >
                Detalles del tour
            </Typography>
            <Box
                sx={{
                    display: 'grid',
                    width: '100%',
                    py: '5px'
                }}
            >
                <CmLabel htmlFor="guideType">¿Como quieres hacer tu tour?</CmLabel>
                <Select
                    labelId="guide-type"
                    name="guideType"
                    value={guide}
                    onChange={handleChangeGuide}
                    MenuProps={MenuProps}
                    sx={{
                        fontSize: '13px',
                        height: '40px',
                        borderRadius: '8px'
                    }}
                    >
                    {guideTypes.map((item) => (
                        <MenuItem
                            key={item}
                            value={item}
                            >
                            {item}
                        </MenuItem>
                    ))}
                </Select>
            </Box>

            <Box
                sx={{
                    display: 'grid',
                    width: '100%',
                    py: '5px'
                }}
            >
                <CmLabel htmlFor="vehicle">Vehículo</CmLabel>
                <Select
                    labelId="vehicle-id"
                    name="vehicle"
                    value={vehicle}
                    onChange={handleChangeVehicle}
                    MenuProps={MenuProps}
                    sx={{
                        fontSize: '13px',
                        height: '40px',
                        borderRadius: '8px'
                    }}
                    >
                    {vehicles.map((item) => (
                        <MenuItem
                            key={item}
                            value={item}
                            >
                            {item}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
            <Box sx={{
                pb: '10px'
            }}>
                <Box>
                    <CmLabel htmlFor="complements">Complementos</CmLabel>
                </Box>
                <FormControlLabel sx={{
                    '& .MuiTypography-body1': {
                        fontSize: {xs: '10px', md: '12px'}
                    },
                    '& .MuiFormControlLabel-labelPlacementEnd': {
                        alignItems: 'flex-start'
                    },
                }}
                    value="complement1"
                    label="Silla para niños"
                    control={
                        <BpRadio
                            checked={complement1}
                            onChange={handleComplement1}
                            value="complement1"
                            name={`radio-buttons-complement1`}
                            inputProps={{ 'aria-label': 'complement1' }}
                        />
                    }
                />
                <FormControlLabel sx={{
                    '& .MuiTypography-body1': {
                        fontSize: {xs: '10px', md: '12px'}
                    },
                    '& .MuiFormControlLabel-labelPlacementEnd': {
                        alignItems: 'flex-start'
                    },
                }}
                    value="complement2"
                    label="Silla para niños"
                    control={
                        <BpRadio
                            checked={complement2}
                            onChange={handleComplement2}
                            value="complement2"
                            name={`radio-buttons-complement2`}
                            inputProps={{ 'aria-label': 'complement2' }}
                        />
                    }
                />
            </Box>
            <Box
                sx={{
                    display: 'grid',
                    width: '100%',
                    py: '5px'
                }}
            >
                <CmLabel htmlFor="atractions">Atracciones del tour</CmLabel>
                <Select
                    labelId="atractions-id"
                    name="atractions"
                    value={atraction}
                    onChange={handleChangeAtraction}
                    MenuProps={MenuProps}
                    sx={{
                        fontSize: '13px',
                        height: '40px',
                        borderRadius: '8px'
                    }}
                    >
                    {atractions.map((item) => (
                        <MenuItem
                            key={item}
                            value={item}
                            >
                            {item}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
        </>
    )
}

export default Step2;