import React from 'react'
import { Box } from '@mui/material'
import sideImage from '../../../assets/images/teamwork.jpg'
import centerImage from '../../../assets/images/people-smiling.png'
import './styles.css'

const ImagesBanner = () => (
    <Box sx={{
        display: "flex",
        gap: 1,
        justifyContent: 'center',
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
    }}>
        <img className='image' src={sideImage} />
        <img className='image' src={centerImage} />
        <img className='image' src={sideImage} />
    </Box>
)

export default ImagesBanner
