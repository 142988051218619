import React, { useState } from "react";

import { Box, FormControlLabel, Link, MenuItem, RadioGroup, Select, Typography } from "@mui/material";
import BpRadio from "./BpRadio";

const CmLabel = (props) => {
    const { children, ...lProps } = props;
    return (
        <label style={{color: '#6D7280', fontSize: '14px'}} {...lProps}>{children}</label>
    );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Step2 = () => {
    const [foodOption, setFoodOption] = useState(false)
    const [insuranceOption, setInsuranceOption] = useState(false)

    const handleFoodOption = (event) => {
        setFoodOption(event.target.value);
    }

    const handleInsuranceOption = (event) => {
        setInsuranceOption(event.target.value);
    }

    return (
        <>
            <Typography
                variant="h5"
                sx={{
                    fontSize: '22px',
                    color: 'secondary.main',
                    lineHeight: 1,
                    mb: '20px'
                }}
            >
                ¿Qué quieres incluir?
            </Typography>
            <Box sx={{
                pb: '10px'
            }}>
                <Box>
                    <CmLabel htmlFor="complements">¿Quieres incluir comida en tu tour?</CmLabel>
                </Box>
                <RadioGroup
                    aria-labelledby="food-options-radio-buttons-group"
                    name="controlled-radio-buttons-group1"
                    value={foodOption}
                    onChange={handleFoodOption}
                    sx={{
                        display: 'block'
                    }}
                >
                    <FormControlLabel sx={{
                        '& .MuiTypography-body1': {
                            fontSize: {xs: '10px', md: '12px'}
                        },
                        '& .MuiFormControlLabel-labelPlacementEnd': {
                            alignItems: 'flex-start'
                        },
                    }}
                        value="sinComida"
                        label="Sin comida"
                        control={
                            <BpRadio
                                name={`radio-buttons-complement1`}
                                inputProps={{ 'aria-label': 'complement1' }}
                            />
                        }
                    />
                    <FormControlLabel sx={{
                        '& .MuiTypography-body1': {
                            fontSize: {xs: '10px', md: '12px'}
                        },
                        '& .MuiFormControlLabel-labelPlacementEnd': {
                            alignItems: 'flex-start'
                        },
                    }}
                        value="conComida"
                        label="Con comida"
                        control={
                            <BpRadio
                                name={`radio-buttons-complement2`}
                                inputProps={{ 'aria-label': 'complement2' }}
                            />
                        }
                    />
                </RadioGroup>
                            
            </Box>
            <Box sx={{
                pb: '10px'
            }}>
                <Box>
                    <CmLabel htmlFor="complements">
                        ¿Qué seguro quieres para tu tour?&nbsp;
                        <Link
                            href="#"
                            sx={{
                                textDecoration: 'none'
                            }}
                        >Leer más</Link>
                    </CmLabel>
                </Box>
                <RadioGroup
                    aria-labelledby="insurance-options-radio-buttons-group"
                    name="controlled-radio-buttons-group2"
                    value={insuranceOption}
                    onChange={handleInsuranceOption}
                    sx={{
                        display: 'block'
                    }}
                >
                    <FormControlLabel sx={{
                        '& .MuiTypography-body1': {
                            fontSize: {xs: '10px', md: '12px'}
                        },
                        '& .MuiFormControlLabel-labelPlacementEnd': {
                            alignItems: 'flex-start'
                        },
                    }}
                        value="basico"
                        label="Básico"
                        control={
                            <BpRadio
                                name={`radio-buttons-complement1`}
                                inputProps={{ 'aria-label': 'complement1' }}
                            />
                        }
                    />
                    <FormControlLabel sx={{
                        '& .MuiTypography-body1': {
                            fontSize: {xs: '10px', md: '12px'}
                        },
                        '& .MuiFormControlLabel-labelPlacementEnd': {
                            alignItems: 'flex-start'
                        },
                    }}
                        value="completo"
                        label="Completo"
                        control={
                            <BpRadio
                                name={`radio-buttons-complement2`}
                                inputProps={{ 'aria-label': 'complement2' }}
                            />
                        }
                    />
                </RadioGroup>
                
            </Box>
        </>
    )
}

export default Step2;