import React from "react"
import DeskopFooter from "./DesktopFooter"
import { useScreenDetector } from "../../hooks/useScreenDetector"

const Footer2= () => {
    const {isMobile} = useScreenDetector()
    return <DeskopFooter />
}

export default Footer2
