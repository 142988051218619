import React from "react"
import {
    Box,
    Container,
    Typography
} from "@mui/material"
import { useNavigate } from "react-router-dom"
import { KeyboardDoubleArrowRight } from "@mui/icons-material"
import BlogArticle from "../../components/BlogArticle"
import Breadcrumbs from "../../components/Breadcrumbs"
import BlogArticleButtons from "../../components/BlogArticleButtons"
import BlurImageWrapper from "../../components/BlurImageWrapper"
import DestinationGallery from "../../components/DestinationGallery"
import MainLayout from "../../components/MainLayout"
import { fetchBlogDataBySlug } from "../../utils/fetchBlogData"
import { blogDestinations } from "../../utils/data"



const GuiasBlog = () => {
    const [pageData, setPageData] = React.useState({})
    const [page, country, city, slug] = window.location.pathname.split("/").filter(Boolean)
    const navigate = useNavigate()
    const breadcrumbsArray = [{ 
        name: "Guías de viaje",
        path: `/${page}` 
    }]
    const [breadcrumbs, setBreadcrumbs] = React.useState(breadcrumbsArray)

    const assignBreadcrumbs = React.useCallback((pageData) => {
        const tempBreadcrumbs = []
        tempBreadcrumbs.push({
            name: pageData?.title,
            path: `/${page}/${country}/${city}`
        })
        if (slug) {
            tempBreadcrumbs.push({
                name: pageData?.titleDescription,
                path: `/${page}/${country}/${city}/${slug}`
            })
        }
        setBreadcrumbs(current => [...current, ...tempBreadcrumbs])
    }, [city, country, page, slug])

    React.useEffect(() => {
        (async () => {
            const data = await fetchBlogDataBySlug(slug || "principal")
            
            if (Object.keys(data)?.length) {
                setPageData(data)
                assignBreadcrumbs(data)
            } else {
                navigate("/not-found")
            }
        })()
    }, [assignBreadcrumbs, city, country, navigate, page, slug])


    return (
        <MainLayout>
            <BlurImageWrapper imageUrl={pageData?.heroImage} >
                <Container sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    py: "4rem"
                }}>
                    <Typography
                        color="primary"
                        sx={{
                            fontWeight: 500,
                            lineHeight: 0,
                        }}
                        variant="textBody"
                    >
                        Guía de viaje
                    </Typography>
                    <Typography
                        variant="h3"
                        color="secondary.contrastText"
                        sx={{
                            fontWeight: "bold",
                            mb: "1rem"
                        }}
                    >
                        {pageData?.title}
                    </Typography>
                    <Typography color="secondary.contrastText" variant="subtitle1">
                        {pageData?.titleDescription}
                    </Typography>
                </Container>
            </BlurImageWrapper>
            <Container>
                <Breadcrumbs breadcrumbs={breadcrumbs} />
                <BlogArticleButtons
                    placeName={pageData?.title}
                    activePath={window.location.pathname}
                    path={`/${page}/${country}/${city}`}
                />
                <Typography
                    color="secondary"
                    sx={{
                        fontSize: 32,
                        fontFamily: 'Poppins',
                        fontWeight: 'bold',
                        lineHeight: 1.5,
                        mb: "1.5rem",
                    }}
                >
                    {pageData?.subTitle}
                </Typography>
                <Typography component="p" color="blackArticle" variant="body3" sx={{mb: "3rem"}}>
                    {pageData?.description}
                </Typography>
                {pageData?.sections?.length ? (
                    pageData.sections.map((section, index) => (
                        <Box key={`${section.title}${index}`} sx={{mb: {xs: "4rem", md: "6rem"}}}>
                            <Box sx={{
                                alignItems: "center",
                                display: "flex",
                                flexDirection: "row",
                                mb: "2rem"
                            }}>
                                <KeyboardDoubleArrowRight
                                    color="secondary"
                                    fontSize="small"
                                    sx={{
                                        mr: "12px"
                                    }}
                                />
                                <Typography
                                    color="secondary"
                                    sx={{
                                        fontSize: 24,
                                        fontFamily: 'Poppins',
                                        fontWeight: 'bold',
                                        lineHeight: 1.5,
                                    }}
                                >
                                    {section?.title}
                                </Typography>
                            </Box>
                            {
                                section?.articles.length ? (
                                    section.articles.map((article, index) => (
                                        <BlogArticle key={`${article.title}${index}`} {...article} />
                                    ))
                                ) : null
                            }
                        </Box>
                    ))
                ) : null} 
            </Container>
            <DestinationGallery title="Otros han buscado" destinations={blogDestinations} textPosition="center"/>
        </MainLayout>
    )
}

export default GuiasBlog
