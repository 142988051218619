import React from "react"
import "./styles.css";
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';


const GridGallery = ({ images, alt }) => {    
    React.useEffect(() => {
        let lightbox = new PhotoSwipeLightbox({
            gallery: '#grid-galery',
            children: 'a',
            pswpModule: () => import('photoswipe'),
        });
        lightbox.init();

        return () => {
            lightbox.destroy();
            lightbox = null;
        };
    }, [])

    return (
        <div className="grid-container" id="grid-galery">
            {
                images.map((img, index) => (
                    <a
                        key={`image${index+1}`}
                        href={img}
                        className={`
                            ${index < 4 ? `image${index + 1}` : "hide-img"} 
                            ${index !== 3 ? "inactive-link" : ""}`
                        }
                        target="_blank"
                        data-pswp-width={900}
                        data-pswp-height={500}
                        rel="noreferrer"
                    >
                        <img
                            alt={`${alt} Imagen ${index+1}`}
                            className="grid-img"
                            src={img}
                        />
                        {index === 3 ? <p className="last-img_text">Ver más fotos</p> : null}
                    </a>
                ))
            }
        </div>
    )
}

export default GridGallery
