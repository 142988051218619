import React, { useState } from "react";

import CheckoutLayout from "../../components/CheckoutLayout";
import { Box, Container, Step, StepLabel, Stepper } from "@mui/material";
import Step1 from "./Step1";
import './styles.css'
import Step2 from "./Step2";
import Step3 from "./Step3";
import UpdateTourModal from "../../components/UpdateTourModal";
import CheckoutContext from "../../components/CheckoutContext/checkoutContext";
import { useDispatch, useSelector } from "react-redux";
import { remove, selectTours } from "../../app/features/cartSlice";
import { useScreenDetector } from "../../hooks/useScreenDetector";
import UpdateTourDialog from "../../components/UpdateTourDialog";


const steps = ['Revisa tu carrito', 'Datos personales', 'Metodo de pago'];

const Checkout = () => {
    const cartTours = useSelector(selectTours);
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [open, setOpen] = useState(false);
    const [selectedTour, setSelectedTour] = useState(null);
    const { isMobile } = useScreenDetector()
    const dispatch = useDispatch();

    const handleRemoveTour = (id) => dispatch(remove(id))
    
    const handleClose = () => setOpen(false);

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleEditTour = (id) => {
        console.log('Id: ', id);
        setSelectedTour(cartTours.find(tour => tour.id === id));
        setOpen(true);
    }

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    return (
        <CheckoutContext.Provider value={{
            cartTours: cartTours,
            handleEditTour: handleEditTour,
            handleRemoveTour: handleRemoveTour
        }}>
            {selectedTour && !isMobile && <UpdateTourModal open={open} handleClose={handleClose} tour={selectedTour} />}
            {selectedTour && isMobile && <UpdateTourDialog open={open} handleClose={handleClose} tour={selectedTour}/>}
            <CheckoutLayout>
                <Container id="checkout-container">
                    <Box sx={{ width: '100%', mt: {xs: '40px', md: '60px'} }}>
                        <Stepper activeStep={activeStep}>
                            {steps.map((label, index) => {
                                return (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                            <Box sx={{mt: {xs: '40px', md: '20px'}}}>
                                {activeStep === 0 &&
                                    <Step1 handleNext={handleNext}/> 
                                }
                                {activeStep === 1 &&
                                    <Step2 handleNext={handleNext}/> 
                                }
                                {activeStep === 2 &&
                                    <Step3 handleNext={handleNext}/> 
                                }
                            </Box>
                        </Box>
                </Container>
            </CheckoutLayout>
        </CheckoutContext.Provider>
    )

}

export default Checkout;