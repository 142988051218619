import React from "react"
import { Box, Button, Tabs as MaterialTabs, Tab } from "@mui/material"
import { useScreenDetector } from "../../hooks/useScreenDetector"

const Tabs = ({ activeTab, tabs, onChangeTab }) => {
    const { isMobile } = useScreenDetector()

    const MobileTabs = () => (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap",
                gap: "1rem"
            }}>
            {tabs.map((tab, index) => {
                const isActive = index === activeTab
                return (
                    <Button
                        key={index}
                        variant={isActive ? "contained" : "outlined"}
                        color={isActive ? "secondary" : "primary"}
                        sx={{
                            borderRadius: "16px",
                            color: isActive ? "#ffffff" : "#40C5E8",
                            display: "grid",
                            height: "fit-content",
                            textTransform: "none"
                        }}
                        onClick={onChangeTab(index)}>
                        {tab.name}
                    </Button>
                )
            })}
        </Box>
    )

    const DesktopTabs = () => (
        <MaterialTabs
            value={activeTab}
            onChange={onChangeTab()}
            textColor="secondary"
            indicatorColor="primary"
            TabIndicatorProps={{
                sx: {
                    display: "none"
                }
            }}>
            {tabs.map((tab, index) => (
                <Tab
                    key={tab.name}
                    value={index}
                    label={tab.name}
                    sx={{
                        fontSize: 20,
                        fontFamily: "Poppins",
                        lineHeight: 1.5,
                        fontWeight: 400,
                        textTransform: "none"
                    }}
                />
            ))}
        </MaterialTabs>
    )

    return isMobile ? <MobileTabs /> : <DesktopTabs />
}

export default Tabs
