import React from "react"
import { Box, Typography } from "@mui/material"
import { Check, Add} from "@mui/icons-material"
import TagButton from "../TagButton"
import { ReactComponent as ChurchIcon } from "../../assets/icons/church.svg"
import { ReactComponent as FoodIcon } from "../../assets/icons/food.svg"
import { ReactComponent as TicketIcon } from "../../assets/icons/ticket-fill.svg"
import { ReactComponent as ClockIcon } from "../../assets/icons/clock-gray.svg"

const ICONS = {
    religion: ChurchIcon,
    alimentos: FoodIcon,
    entretenimiento: TicketIcon,
}

export default function ItineraryItem ({
    title,
    description,
    duration,
    added,
    category,
    onClickAdd,
}) {
    const Icon = ICONS[category]
    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mb: {xs: "1rem", md: "2rem"}}}>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
            }}>
                <Icon height={24} width={24} />
                <Typography component="h3" color="secondary" variant="subtitle3" sx={{ml: "5px"}}>
                    {title}
                </Typography>
            </Box>
            <Typography sx={{ fontSize: 16, fontFamily: 'Inter' }}>
                {description}
            </Typography>
            <Box sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
            }}>
                <ClockIcon height={20} width={20} />
                <Typography sx={{ fontSize: 16, fontFamily: 'Inter', ml: "5px" }}>
                    {`Duración: ${duration}`}
                </Typography>
            </Box>
            <TagButton
                text={added ? "Agregado" : "Agregar a mi tour"}
                color={added ? "secondary" : "primary"}
                endIcon={added ? <Check /> : <Add />}
                onClick={() => onClickAdd(title)}
                sx={{
                    width: "fit-content",
                    px: "1rem",
                    py: "5px",
                    maxWidth: "12rem",
                }}
            />
        </Box>
    )
}
