import {Avatar, IconButton} from "@mui/material"
import { ReactComponent as InstagramLogo } from "../../assets/icons/instagram.svg";
import { ReactComponent as FacebookLogo } from "../../assets/icons/facebook.svg";
import { ReactComponent as LinkedinLogo } from "../../assets/icons/linkedin.svg";
import { ReactComponent as TiktokLogo } from "../../assets/icons/tiktok.svg";
import { ReactComponent as YoutubeLogo } from "../../assets/icons/youtube.svg";

export const socialVariants = {
    instagram: {
        icon: InstagramLogo,
        url: "https://www.instagram.com/trekzy"
    },
    facebook: {
        icon: FacebookLogo,
        url: "https://www.facebook.com/treckzy"
    },
    linkedin: {
        icon: LinkedinLogo,
        url: "https://www.linkedin.com/in/trekzy"
    },
    tiktok: {
        icon: TiktokLogo,
        url: "https://www.tiktok.com/trekzy"
    },
    youtube: {
        icon: YoutubeLogo,
        url: "https://www.youtube.com/trekzy"
    }
}
const SocialLink = ({variant = ""}) => {

    const linkData = socialVariants[`${variant}`]

    if (!linkData) return null

    const {icon: Icon, url} = linkData

    return (
        <IconButton href={url} >
            <Avatar sx={{
                backgroundColor: "#223F7F",
            }}>
                <Icon style={{width: "1rem", height: "1rem"}}/>
            </Avatar>
        </IconButton>
    )
}

export default SocialLink
