import React from 'react'
import { Box, Button, Container, Collapse, Divider, Link } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import SocialLinksSection from './SocialLinksSection'
import PayMethodsSection from './PayMethodsSection'
import { ReactComponent as TrekzyLogo } from '../../assets/images/trekzy-logo.svg'

const Footer = () => {
    const [expandSections, setExpandSection] = React.useState([
        {
            expanded: true,
            name: 'Destinos',
            content: [
                { link: '/#', linkText: 'Europa' },
                {
                    link: '/#',
                    linkText: 'América del norte'
                },
                {
                    link: '/#',
                    linkText: 'América del norte'
                },
                {
                    link: '/#',
                    linkText: 'Asía'
                }
            ]
        },
        {
            expanded: false,
            name: 'Guías de viaje',
            content: [
                { link: '/guias-de-viaje', linkText: 'Todas las guías' },
                {
                    link: '/guias-de-viaje/espana/barcelona',
                    linkText: 'Guía de Barcelona'
                },
                {
                    link: '/guias-de-viaje/eua/new-york',
                    linkText: 'Guía de New York'
                },
                {
                    link: '/guias-de-viaje/reino-unido/londres',
                    linkText: 'Guía de Londres'
                }
            ]
        },
        {
            expanded: false,
            name: 'Especialidades',
            content: [
                { link: '/blog', linkText: 'Blog de viaje' },
                { link: '/sorprendeme', linkText: 'Sorpréndeme' }
            ]
        },
        {
            expanded: false,
            name: 'Trekzy',
            content: [
                { link: ' /contacto', linkText: 'Contacto' },
                {
                    link: '/quienes-somos',
                    linkText: 'Quiénes somos'
                },
                {
                    link: '/ayuda',
                    linkText: 'FAQs'
                },
                {
                    link: '/legales',
                    linkText: 'Términos y Condiciones'
                },
                {
                    link: '/politicas-de-privacidad',
                    linkText: 'Política de Privacidad'
                },
                {
                    link: '/politicas-de-cancelaciones',
                    linkText:
                        'Política General de Modificaciones y Cancelaciones'
                }
            ]
        }
    ])

    const onColapseSection = (index) => () => {
        const updatedSections = expandSections
        updatedSections[index].expanded = !expandSections[index].expanded
        setExpandSection([...updatedSections])
    }

    return (
        <Box
            sx={{
                backgroundColor: '#F0F0F1',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                pt: '6rem',
                pb: '5rem'
            }}>
            <TrekzyLogo />
            <SocialLinksSection />
            {expandSections.map((section, index) => (
                <Container sx={{ mb: '1rem' }} key={section.name}>
                    <Button
                        endIcon={
                            section.expanded ? <ExpandMore /> : <ExpandLess />
                        }
                        variant="text"
                        sx={{
                            color: '#223F7F',
                            fontFamily: 'Poppins',
                            fontSize: '18px',
                            fontWeight: 700,
                            width: '100%'
                        }}
                        onClick={onColapseSection(index)}>
                        {section.name}
                    </Button>
                    <Divider
                        aria-hidden="true"
                        color="secondary"
                        sx={{ height: 2 }}
                    />
                    <Collapse
                        in={expandSections[index].expanded}
                        timeout="auto"
                        unmountOnExit>
                        <Box
                            sx={{
                                color: '#666B6E',
                                display: 'flex',
                                flexDirection: 'column',
                                fontFamily: 'Inter',
                                fontSize: '1rem',
                                textAlign: 'center'
                            }}>
                            {section?.content?.map((cont, index) => {
                                const { link, linkText } = cont
                                return (
                                    <Link
                                        key={index}
                                        sx={{ py: '.5rem' }}
                                        underline="none"
                                        color="inherit"
                                        href={link}>
                                        {linkText}
                                    </Link>
                                )
                            })}
                        </Box>
                    </Collapse>
                </Container>
            ))}
            <PayMethodsSection />
            <Container
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    color: '#666B6E',
                    fontSize: '14px',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start'
                }}>
                <Link
                    sx={{ textWrap: 'nowrap', mr: '1rem', mb: '1rem' }}
                    color="grayText"
                    underline="none"
                    href="/legales">
                    Términos y Condiciones
                </Link>
                <Link
                    sx={{ textWrap: 'nowrap', mb: '1rem' }}
                    color="grayText"
                    underline="none"
                    href="/politicas-de-privacidad">
                    Política de Privacidad
                </Link>
                <Link
                    sx={{ textWrap: 'nowrap' }}
                    color="grayText"
                    underline="none"
                    href="/politicas-de-cancelaciones">
                    Política General de Modificaciones y Cancelaciones
                </Link>
            </Container>
        </Box>
    )
}

export default Footer
