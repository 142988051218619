import { createBrowserRouter } from 'react-router-dom'
import AboutUs from './pages/AboutUs'
import Booking from './pages/Booking'
import Checkout from './pages/Checkout'
import CancelationPolicies from './pages/CancelationPolicies'
import Destination from './pages/Destination'
import DestinationsCountry from './pages/DestinationsCountry'
import DestinationsPrincipal from './pages/DestinationsPrincipal'
import ErrorPage from './pages/Error'
import Faqs from './pages/Faqs'
import GuiasDeViaje from './pages/GuiasDeViaje'
import GuiasBlog from './pages/GuiasBlog'
import Home from './pages/Home'
import PrivacyPolicies from './pages/PrivacyPolicies'
import Tour from './pages/Tour'
import CartMobile from './pages/CartMobile'
import Terms from './pages/Terms'

export const router = createBrowserRouter([
    {
        path: '/',
        element: <Home />
    },
    {
        path: '/ayuda',
        element: <Faqs />
    },
    {
        path: '/checkout',
        element: <Checkout />
    },
    {
        path: 'confirmacion-reserva',
        element: <Booking />
    },
    {
        path: '/cart',
        element: <CartMobile />
    },
    {
        path: '/destinos',
        element: <DestinationsPrincipal />
    },
    {
        path: '/destinos/:country',
        element: <DestinationsCountry />
    },
    {
        path: '/destinos/:country/:city',
        element: <Destination />
    },
    {
        path: '/destinos/:country/:city/:slug',
        element: <Tour />
    },
    {
        path: '/guias-de-viaje',
        element: <GuiasDeViaje />
    },
    {
        path: '/guias-de-viaje/:country/:city',
        element: <GuiasBlog />
    },
    {
        path: '/guias-de-viaje/:country/:city/:slug',
        element: <GuiasBlog />
    },
    {
        path: '/legales',
        element: <Terms />
    },
    {
        path: '/not-found',
        element: <ErrorPage />
    },
    {
        path: '/politicas-de-cancelaciones',
        element: <CancelationPolicies />
    },
    {
        path: '/politicas-de-privacidad',
        element: <PrivacyPolicies />
    },
    {
        path: '/quienes-somos',
        element: <AboutUs />
    },
    {
        path: '*',
        element: <ErrorPage />
    }
])
