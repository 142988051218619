import React from 'react';
import { Box, Container, Typography } from '@mui/material'
import BlurImageWrapper from '../BlurImageWrapper'
import bgImage from '../../assets/images/hero2.png'

const DataBox = (labelText, value) => (
    <Box sx={{pl: '25px'}}>
        <Typography
            variant="body1"
            component="p"
            sx={{
                color: 'primary.contrastText',
                fontSize: { xs: '18px', md: '22px' },
                fontWeight: 500,
            }}
        >
            {value}
        </Typography>
        <Typography
            variant="body1"
            component="p"
            sx={{
                color: 'primary.contrastText',
                fontSize: {xs: '12px', md: '16px'}
            }}
        >
            {labelText}
        </Typography>
    </Box>
)

const DestinationHero = ({
    country,
    city,
    numberComments,
    media,
    numberActivities
}) => (
    <BlurImageWrapper imageUrl={bgImage}>
        <Container
            sx={{
                height: 'fit-content',
                pt: { xs: '3rem', md: '5.5rem' },
            }}>
            <Box sx={{
                width: '100%',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
            }}>
                <Typography
                    variant="body1"
                    component="p"
                    sx={{
                        color: 'primary.main',
                        fontSize: '24px',
                        fontWeight: '700'
                    }}
                >
                    Tours privados
                </Typography>
                <Typography
                    variant="h3"
                    component="h1"
                    sx={{
                        color: 'primary.contrastText',
                        fontSize: '24px'
                    }}
                >
                    {city}
                </Typography>
                <Typography
                    variant="body1"
                    component="p"
                    sx={{
                        color: 'primary.contrastText',
                        fontSize: '22px'
                    }}
                >
                    {country}
                </Typography>
            </Box>
            <Box sx={{pb: '20px', display: 'flex'}}>
                {DataBox('Opiniones', numberComments)}
                {DataBox('Puntuacion media', media)}
                {DataBox('Actividades en total', numberActivities)}
            </Box>
        </Container>
    </BlurImageWrapper>
);

export default DestinationHero;