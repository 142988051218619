import {
    cityPrincipalBlogData,
    whatToSeeBlogData,
    excursionsBlogData,
    toDoBlogData,
    lodgingBlogData,
    gastronomyBlogData,
} from "./guidesBlogMockData"

export const fetchBlogDataBySlug = (slug) => {
    let data = {}
    switch (slug) {
        case "principal":
            data = cityPrincipalBlogData;
            break;
        case "que-ver":
            data = whatToSeeBlogData;
            break;
        case "excursiones":
            data = excursionsBlogData;
            break;
        case "que-hacer":
            data = toDoBlogData;
            break;
        case "donde-dormir":
            data = lodgingBlogData;
            break;
        case "gastronomia":
            data = gastronomyBlogData;
            break;
        case "que-hacer-en-pareja":
            data = {
                ...cityPrincipalBlogData,
                titleDescription: "Qué hacer en pareja en Granada",
                subtitle: "Qué hacer en pareja en Granada",
            }
            break
        default:
            data = {}
            break;
    }

    return new Promise((res) => res(data))
}