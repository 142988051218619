import { Box, Typography } from '@mui/material'

const SingleCard = ({ title, description, style = {} }) => {
    return (
        <Box
            sx={{
                ...style,
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
                p: { xs: 0, md: '1.2rem' },
                justifyContent: 'flex-start',
                textAlign: 'flex-start',
                mx: 0,
            }}>
            <Typography color="secondary" variant="h5">
                {title}
            </Typography>
            <Typography
                sx={{
                    color: '#3E3A39',
                    fontFamily: 'Inter',
                    fontSize: 18
                }}>
                {description}
            </Typography>
        </Box>
    )
}

export default SingleCard
