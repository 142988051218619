export const cityPrincipalBlogData = {
    title: "Granada",
    titleDescription: "Información principal",
    heroImage: require("../assets/images/granada.png"),
    subTitle: "Información principal",
    description: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
    sections: [
        {
            title: "Cómo llegar a Granada",
            articles: [
                {
                    articleTitle: "Desde el aereopuerto",
                    content: "Lorem ipsum dolor sit amet consectetur. <strong>Egestas ipsum nunc morbi nulla  turpis.</strong> Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
                    imageUrl: require("../assets/images/airplane.png"),
                    imageDescription: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. <strong>Porta feugiat nam gravida nisi.</strong>",
                },
                {
                    articleTitle: "Ir a Granada en coche",
                    content: "Lorem ipsum dolor sit amet consectetur. <strong>Egestas ipsum nunc morbi nulla  turpis.</strong> Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
                    imageUrl: "",
                    imageDescription: "",
                },
                {
                    articleTitle: "Ir a Granada en bus",
                    content: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla  turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
                    imageUrl: "",
                    imageDescription: "",
                },
            ]
        },
        {
            title: "Principales consejos para tu visita",
            articles: [
                {
                    articleTitle: "Desde el aereopuerto",
                    content: "Lorem ipsum dolor sit amet consectetur. <strong>Egestas ipsum nunc morbi nulla  turpis.</strong> Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
                    imageUrl: require("../assets/images/la-coruna.png"),
                    imageDescription: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. <strong>Porta feugiat nam gravida nisi.</strong>",
                },
                {
                    articleTitle: "Museo de Artes Visuales 1",
                    content: "Lorem ipsum dolor sit amet consectetur. <strong>Egestas ipsum nunc morbi nulla  turpis.</strong> Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
                    imageUrl: "",
                    imageDescription: "",
                },
                {
                    articleTitle: "Museo de Artes Visuales 2",
                    content: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla  turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
                    imageUrl: "",
                    imageDescription: "",
                },
            ]
        },
        {
            title: "Cómo llegar a Granada",
            articles: [
                {
                    articleTitle: "Desde el aereopuerto",
                    content: "Lorem ipsum dolor sit amet consectetur. <strong>Egestas ipsum nunc morbi nulla  turpis.</strong> Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
                    imageUrl: require("../assets/images/airplane.png"),
                    imageDescription: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. <strong>Porta feugiat nam gravida nisi.</strong>",
                },
                {
                    articleTitle: "Ir a Granada en coche",
                    content: "Lorem ipsum dolor sit amet consectetur. <strong>Egestas ipsum nunc morbi nulla  turpis.</strong> Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
                    imageUrl: "",
                    imageDescription: "",
                },
                {
                    articleTitle: "Ir a Granada en bus",
                    content: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla  turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
                    imageUrl: "",
                    imageDescription: "",
                },
            ]
        },
    ]
}
export const whatToSeeBlogData = {
    title: "Granada",
    titleDescription: "Qué ver en Granada",
    heroImage: require("../assets/images/granada.png"),
    subTitle: "Qué ver en Granada",
    description: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
    sections: [
        {
            title: "Principales sitios a visitar",
            articles: [
                {
                    articleTitle: "La Alhambra",
                    content: "Lorem ipsum dolor sit amet consectetur. <strong>Egestas ipsum nunc morbi nulla  turpis.</strong> Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
                    imageUrl: require("../assets/images/granada.png"),
                    imageDescription: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. <strong>Porta feugiat nam gravida nisi.</strong>",
                },
                {
                    articleTitle: "El Cármen de los Mártires",
                    content: "Lorem ipsum dolor sit amet consectetur. <strong>Egestas ipsum nunc morbi nulla  turpis.</strong> Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
                    imageUrl: require("../assets/images/bangkok.png"),
                    imageDescription: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. <strong>Porta feugiat nam gravida nisi.</strong>",
                },
            ]
        },
        {
            title: "Principales sitios a visitar",
            articles: [
                {
                    articleTitle: "La Alhambra",
                    content: "Lorem ipsum dolor sit amet consectetur. <strong>Egestas ipsum nunc morbi nulla  turpis.</strong> Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
                    imageUrl: require("../assets/images/la-coruna.png"),
                    imageDescription: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. <strong>Porta feugiat nam gravida nisi.</strong>",
                },
                    {
                    articleTitle: "El Cármen de los Mártires",
                    content: "Lorem ipsum dolor sit amet consectetur. <strong>Egestas ipsum nunc morbi nulla  turpis.</strong> Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
                    imageUrl: require("../assets/images/bangkok.png"),
                    imageDescription: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. <strong>Porta feugiat nam gravida nisi.</strong>",
                },
            ]
        },
    ]
}
export const excursionsBlogData = {
    title: "Granada",
    titleDescription: "Excursiones en Granada",
    heroImage: require("../assets/images/granada.png"),
    subTitle: "Excursiones en Granada",
    description: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
    sections: [
        {
            title: "Principales sitios a visitar",
            articles: [
                {
                    articleTitle: "La Alhambra",
                    content: "Lorem ipsum dolor sit amet consectetur. <strong>Egestas ipsum nunc morbi nulla  turpis.</strong> Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
                    imageUrl: require("../assets/images/granada.png"),
                    imageDescription: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. <strong>Porta feugiat nam gravida nisi.</strong>",
                },
                {
                    articleTitle: "El Cármen de los Mártires",
                    content: "Lorem ipsum dolor sit amet consectetur. <strong>Egestas ipsum nunc morbi nulla  turpis.</strong> Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
                    imageUrl: require("../assets/images/bangkok.png"),
                    imageDescription: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. <strong>Porta feugiat nam gravida nisi.</strong>",
                },
            ]
        },
        {
            title: "Principales sitios a visitar",
            articles: [
                {
                    articleTitle: "La Alhambra",
                    content: "Lorem ipsum dolor sit amet consectetur. <strong>Egestas ipsum nunc morbi nulla  turpis.</strong> Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
                    imageUrl: require("../assets/images/la-coruna.png"),
                    imageDescription: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. <strong>Porta feugiat nam gravida nisi.</strong>",
                },
                    {
                    articleTitle: "El Cármen de los Mártires",
                    content: "Lorem ipsum dolor sit amet consectetur. <strong>Egestas ipsum nunc morbi nulla  turpis.</strong> Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
                    imageUrl: require("../assets/images/bangkok.png"),
                    imageDescription: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. <strong>Porta feugiat nam gravida nisi.</strong>",
                },
            ]
        },
    ]
}
export const toDoBlogData = {
    title: "Granada",
    titleDescription: "Qué hacer en Granada",
    heroImage: require("../assets/images/granada.png"),
    subTitle: "Qué hacer en Granada",
    description: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
    sections: [
        {
            title: "Principales sitios a visitar",
            articles: [
                {
                    articleTitle: "La Alhambra",
                    content: "Lorem ipsum dolor sit amet consectetur. <strong>Egestas ipsum nunc morbi nulla  turpis.</strong> Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
                    imageUrl: require("../assets/images/granada.png"),
                    imageDescription: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. <strong>Porta feugiat nam gravida nisi.</strong>",
                },
                {
                    articleTitle: "El Cármen de los Mártires",
                    content: "Lorem ipsum dolor sit amet consectetur. <strong>Egestas ipsum nunc morbi nulla  turpis.</strong> Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
                    imageUrl: require("../assets/images/bangkok.png"),
                    imageDescription: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. <strong>Porta feugiat nam gravida nisi.</strong>",
                },
            ]
        },
        {
            title: "Principales sitios a visitar",
            articles: [
                {
                    articleTitle: "La Alhambra",
                    content: "Lorem ipsum dolor sit amet consectetur. <strong>Egestas ipsum nunc morbi nulla  turpis.</strong> Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
                    imageUrl: require("../assets/images/la-coruna.png"),
                    imageDescription: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. <strong>Porta feugiat nam gravida nisi.</strong>",
                },
                    {
                    articleTitle: "El Cármen de los Mártires",
                    content: "Lorem ipsum dolor sit amet consectetur. <strong>Egestas ipsum nunc morbi nulla  turpis.</strong> Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
                    imageUrl: require("../assets/images/bangkok.png"),
                    imageDescription: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. <strong>Porta feugiat nam gravida nisi.</strong>",
                },
            ]
        },
    ]
}
export const lodgingBlogData = {
    title: "Granada",
    titleDescription: "Dónde dormir en granada",
    heroImage: require("../assets/images/granada.png"),
    subTitle: "Dónde dormir en granada",
    description: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
    sections: [
        {
            title: "Principales sitios a visitar",
            articles: [
                {
                    articleTitle: "La Alhambra",
                    content: "Lorem ipsum dolor sit amet consectetur. <strong>Egestas ipsum nunc morbi nulla  turpis.</strong> Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
                    imageUrl: require("../assets/images/granada.png"),
                    imageDescription: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. <strong>Porta feugiat nam gravida nisi.</strong>",
                },
                {
                    articleTitle: "El Cármen de los Mártires",
                    content: "Lorem ipsum dolor sit amet consectetur. <strong>Egestas ipsum nunc morbi nulla  turpis.</strong> Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
                    imageUrl: require("../assets/images/bangkok.png"),
                    imageDescription: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. <strong>Porta feugiat nam gravida nisi.</strong>",
                },
            ]
        },
        {
            title: "Principales sitios a visitar",
            articles: [
                {
                    articleTitle: "La Alhambra",
                    content: "Lorem ipsum dolor sit amet consectetur. <strong>Egestas ipsum nunc morbi nulla  turpis.</strong> Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
                    imageUrl: require("../assets/images/la-coruna.png"),
                    imageDescription: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. <strong>Porta feugiat nam gravida nisi.</strong>",
                },
                    {
                    articleTitle: "El Cármen de los Mártires",
                    content: "Lorem ipsum dolor sit amet consectetur. <strong>Egestas ipsum nunc morbi nulla  turpis.</strong> Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
                    imageUrl: require("../assets/images/bangkok.png"),
                    imageDescription: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. <strong>Porta feugiat nam gravida nisi.</strong>",
                },
            ]
        },
    ]
}
export const gastronomyBlogData = {
    title: "Granada",
    titleDescription: "Gastronomía en Granada",
    heroImage: require("../assets/images/granada.png"),
    subTitle: "Gastronomía en Granada",
    description: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
    sections: [
        {
            title: "Principales sitios a visitar",
            articles: [
                {
                    articleTitle: "La Alhambra",
                    content: "Lorem ipsum dolor sit amet consectetur. <strong>Egestas ipsum nunc morbi nulla  turpis.</strong> Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
                    imageUrl: require("../assets/images/granada.png"),
                    imageDescription: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. <strong>Porta feugiat nam gravida nisi.</strong>",
                },
                {
                    articleTitle: "El Cármen de los Mártires",
                    content: "Lorem ipsum dolor sit amet consectetur. <strong>Egestas ipsum nunc morbi nulla  turpis.</strong> Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
                    imageUrl: require("../assets/images/bangkok.png"),
                    imageDescription: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. <strong>Porta feugiat nam gravida nisi.</strong>",
                },
            ]
        },
        {
            title: "Principales sitios a visitar",
            articles: [
                {
                    articleTitle: "La Alhambra",
                    content: "Lorem ipsum dolor sit amet consectetur. <strong>Egestas ipsum nunc morbi nulla  turpis.</strong> Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
                    imageUrl: require("../assets/images/la-coruna.png"),
                    imageDescription: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. <strong>Porta feugiat nam gravida nisi.</strong>",
                },
                    {
                    articleTitle: "El Cármen de los Mártires",
                    content: "Lorem ipsum dolor sit amet consectetur. <strong>Egestas ipsum nunc morbi nulla  turpis.</strong> Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. Porta feugiat nam gravida nisi. Tempus lectus nec porttitor integer odio massa. Nunc neque eu egestas vitae diam turpis est in. Risus urna sapien a sem dui elit mi.",
                    imageUrl: require("../assets/images/bangkok.png"),
                    imageDescription: "Lorem ipsum dolor sit amet consectetur. Egestas ipsum nunc morbi nulla turpis. Lorem arcu a odio volutpat malesuada ullamcorper. Eu diam hac vel sed pretium. <strong>Porta feugiat nam gravida nisi.</strong>",
                },
            ]
        },
    ]
}