import React from "react"
import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material"
import ReviewRatings from "./ReviewRatings"
import ReviewCard from "./ReviewCard"

const Reviews = ({ average, count, reviews }) => {
    if (!reviews.length) return null
    return (
        <>
            <ReviewRatings rate={average} reviewsCount={count} />
            <Typography component="h2" color="secondary" sx={{
                fontSize: {xs: 24, md: 32},
                fontFamily: 'Poppins',
                fontWeight: 'bolder',
                lineHeight: 1.5,
                my: "1rem",
            }}>
                Reviews
            </Typography>
            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: {xs: "1rem", md: "2rem"},
            }}>
                <Typography color="grayText" variant="body3" fontWeight={400} fontFamily="Poppins">
                    {`${count} reviews sobre este tour`}
                </Typography>
                <FormControl size="small" sx={{ width: "10rem" }}>
                    <InputLabel>Ordenar por</InputLabel>
                    <Select
                        label="Ordenar por"
                        onChange={() => { }}
                        sx={{
                            width: "100%"
                        }}
                    >
                        <MenuItem value="asd">Ascendente</MenuItem>
                        <MenuItem value="des">Descendente</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: { xs: 1, md: 3 },
                mb: "2rem",
            }}>
                {
                    reviews.map(review => (
                        <ReviewCard
                            key={review.confirmation}
                            clientName={review.client_name}
                            date={review.date_created}
                            title={review.confirmation}
                            {...review}
                        />
                    ))
                }
            </Box>
        </>
    )
}

export default Reviews
