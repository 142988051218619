import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'

const IconCard = ({ card }) => {
    const { imgSource, title, text, altText } = card
    return (
        <Card
            sx={{
                backgroundColor: "rgba(0,0,0,0)",
                boxShadow: 'none',
                borderRadius: 'unset',
                height: { xs: "12rem", md: "15rem" },
            }}>
            <Box
                sx={{
                    textAlign: 'center',
                    pt: { xs: "1.5rem", md: "2rem" },
                }}>
                <img src={imgSource} alt={altText} />
            </Box>
            <CardContent>
                <Typography
                    color="secondary"
                    gutterBottom
                    sx={{
                        textAlign: 'center',
                        fontSize: '20px',
                        fontWeight: 700
                    }}>
                    <span>{title}</span>
                </Typography>
                <Typography
                    variant="body2"
                    color="secondary"
                    sx={{
                        textAlign: 'center',
                        fontWeight: 400,
                        lineHeight: '24px'
                    }}>
                    <span>{text}</span>
                </Typography>
            </CardContent>
        </Card>
    )
}

export default IconCard
