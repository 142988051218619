import React from "react"
import { Box, Button, Typography } from "@mui/material"

const CouponBox = () => (
    <Box sx={{
        alignItems: "start",
        backgroundColor: '#F0F0F1',
        borderRadius: "5px",
        display: "flex",
        flexDirection: "row",
        p: "1.2rem",
    }}>
        <Typography sx={{
            color: "#40C5E8",
            fontSize: 18,
            fontFamily: 'Inter',
            fontWeight: 700,
            lineHeight: 1.2,
        }}>
            %
        </Typography>
        <Button
            sx={{
                color: "#40C5E8",
                fontSize: 18,
                fontFamily: 'Inter',
                fontWeight: 700,
                lineHeight: 1.2,
                p: 0,
            }}
            onClick={() => { }}
        >
            ¿Tienes un cupón? Haz clic aquí para introducir tu código
        </Button>
    </Box>
)

export default CouponBox
