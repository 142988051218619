import React from "react"
import {Typography} from "@mui/material"
import RichText from "../RichText"

const BlogArticle = ({
    articleTitle,
    content,
    imageUrl,
    imageDescription,
}) => (
    <>
        <Typography
            color="secondary"
            component="p"
            sx={{
                fontSize: 22,
                fontFamily: 'Poppins',
                fontWeight: 'regular',
                lineHeight: 1.5,
                mb: "1rem",
            }}
        >
            {articleTitle}
        </Typography>
        <RichText
            styles={{
                fontFamily: "Inter",
                fontSize: 18,
            }}
            text={content}
        />
        {imageUrl ? (
            <img
                src={imageUrl}
                style={{
                    borderRadius: "5px",
                    objectFit: "cover",
                    objectPosition: "50% 40%",
                    height: "16rem",
                    width: "100%"
                }}
            />
        ) : null}
        {imageDescription ? (
            <RichText
                styles={{
                    fontFamily: "Inter",
                    fontSize: 18,
                }}
                text={imageDescription}
            />
        ) : null}
    </>
)

export default BlogArticle
