import React from "react"
import { Box, Button, Typography } from "@mui/material";
import {AddCircleOutline, RemoveCircleOutline} from "@mui/icons-material"

const IncrementalButtons = ({
    value,
    title,
    onIncrement,
    onDecrement,
}) => (
    <Box sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    }}>
        <Button disabled={value === 0} onClick={() => onDecrement(title)}>
            <RemoveCircleOutline
                color={value > 0 ? "primary" : "grayText"}
            />
        </Button>
        <Typography>
            {value}
        </Typography>
        <Button color="primary" onClick={() => onIncrement(title)}>
            <AddCircleOutline color="primary" />
        </Button>
    </Box>
)

export default IncrementalButtons;
