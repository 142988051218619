import React from "react"
import Hero from "../../components/Hero";
import heroImage from "../../assets/images/red-pin-hero.png"
import Breadcrumbs from "../../components/Breadcrumbs";
import { Container } from "@mui/material";
import CardsGrid from "../../components/CardsGrid";
import MainLayout from "../../components/MainLayout";
import { opacities } from "../../components/BlurImageWrapper";
import { cards } from "./mockData";

const DestinationsPrincipal = () => {
    return (
        <MainLayout>
            <Hero
                bgImage={heroImage}
                opacity={opacities.lighter}
                renderInputSearch={false}
                text="+400 destinos con más de 20.000 actividades"
                title="Destinos"
            />
            <Container>
                <Breadcrumbs breadcrumbs={[{
                    name: "Destinos",
                    path: "destinos"
                }]} />
                <CardsGrid cards={cards} />
            </Container>
        </MainLayout>
    )
}

export default DestinationsPrincipal;
