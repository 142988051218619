import React from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import './imagesGrid.css'
import ImageCard from '../ImageCardWithRating'

const ImagesGrid = ({ title, text, itemData }) => {
    const [isHovered, setIsHovered] = React.useState(false)
    const [imageTitle, setImageTitle] = React.useState('')

    const handleMouseOver = (event) => {
        setImageTitle(event.target.alt)
        setIsHovered(true)
    }

    const handleMouseOut = () => {
        setIsHovered(false)
        setImageTitle('')
    }

    return (
        <Box sx={{ my: '40px' }}>
            <Grid container sx={{ mb: 4 }}>
                <Grid item xs={12} md={9}>
                    <Typography
                        color="primary"
                        variant="subtitle"
                        component="h3"
                        sx={{
                            fontSize: { xs: '20px', md: '32px' },
                            fontWeight: 700
                        }}>
                        {title}
                    </Typography>
                    <Typography variant="body1" component="p">
                        {text}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={3}
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={{ display: { xs: 'none', md: 'flex' } }}>
                    <Button
                        variant="contained"
                        color="primary"
                        href="/destinos"
                        sx={{
                            borderRadius: '16px',
                            height: 'fit-content',
                            color: '#ffffff',
                            display: 'grid',
                            textTransform: 'none'
                        }}>
                        Ver todos los destinos
                    </Button>
                </Grid>
            </Grid>
            <div className="masonry-grid">
                {itemData.map((item, index) => {
                    const hover = isHovered && imageTitle === item.title
                    const masonry =
                        index === 2 || index === 4
                            ? `image-block ${'byindex'}`
                            : 'image-block'

                    return (
                        <div
                            onMouseOver={handleMouseOver}
                            onMouseLeave={handleMouseOut}
                            key={`${index}-${item.title}`}
                            className={masonry}
                            style={{
                                gridArea: `item${index + 1}`
                            }}>
                            <ImageCard
                                imageUrl={item.img}
                                alt={item.title}
                                hover={hover}
                            />
                        </div>
                    )
                })}
            </div>
            <Box
                justifyContent="center"
                alignItems="center"
                sx={{ display: { xs: 'flex', md: 'none' }, mt: { xs: 2 } }}>
                <Button
                    variant="contained"
                    color="primary"
                    href="/destinos"
                    sx={{
                        borderRadius: '16px',
                        height: 'fit-content',
                        color: '#ffffff',
                        display: 'grid',
                        textTransform: 'none'
                    }}>
                    Ver todos los destinos
                </Button>
            </Box>
        </Box>
    )
}

export default ImagesGrid
