/* eslint-disable no-undef */
import React, { useContext, useEffect } from 'react';
import {
    Card,
    CardMedia,
    CardContent,
    Typography,
    CardActions,
    Box,
    Button,
    IconButton
} from '@mui/material';
import './productCard.css';
import { ReactComponent as XSquare } from '../../assets/icons/x-square.svg'
import { ReactComponent as Clock } from '../../assets/icons/clock.svg'
import { ReactComponent as Globe } from '../../assets/icons/globe.svg'
import { ReactComponent as Edit } from '../../assets/icons/edit-2.svg'
import { ReactComponent as Trash } from '../../assets/icons/trash.svg'
import CheckoutContext from '../CheckoutContext/checkoutContext';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { getAssistantsString } from '../../utils/checkout';

dayjs.locale('es');


const ProductCard2 = ({
    data,
    isCart = false,
    isCartModal = false,
}) => {
    const {
        id,
        imageUrl,
        price,
        rate,
        reviews,
        title,
        description,
        languages,
        cancelable,
        time,
        date = '',
        type = '',
        assistants = '',
        moreDetails,
        edit,
        remove,
        optionals,
    } = data;
    const { pax } = optionals;
    const image = require(`../../assets/${imageUrl}`)
    const context = useContext(CheckoutContext);
    return (
        <Card
            id="product-card-2"
            sx={{
                display: {xs: 'block', md: 'flex'},
                height: {xs: '330px', md: 'auto'},
                pr: {xs: 0, md: '10px'},
                my: '15px',
                borderRadius: '8px',
                boxShadow: '0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)'
            }}>
            <CardMedia
                sx={{
                    width: { xs: '100%', md: '160px' },
                    height: { xs: '140px', md: 'auto' }
                }}
                image={image}
                title={title}
            />
            <Box
                sx={{
                    width: '100%',
                    display: {xs: 'block', md: 'flex'},
                    flexDirection: 'column'
                }}
            >
                <CardContent
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        pr: '4px'
                    }}
                >
                    <Typography
                        sx={{ fontSize: '18px!important' }}
                        variant="subtitle1"
                    >{title}</Typography>
                    {moreDetails && <Button
                        sx={{
                            fontSize: {xs: '9px', md: '10px'},
                            py: '2px',
                            height: {xs: 'fit-content', md: 'auto'}
                        }}
                        variant="contained"
                    >Ver más</Button>}
                    {(edit || remove) && <Box
                        sx={{
                            display: 'flex'
                        }}
                    >
                        {edit && <IconButton
                            onClick={() => context.handleEditTour(id)}
                            aria-label='Editar'
                            sx={{
                                height: '17px',
                                px: '3px',
                                py: 0
                            }}
                        >
                            <Edit />
                        </IconButton>}
                        {remove && <IconButton
                            onClick={() => context.handleRemoveTour(id)}
                            aria-label='Remover'
                            sx={{
                                height: '17px',
                                px: '3px',
                                py: 0
                            }}
                        >
                            <Trash />
                        </IconButton>}
                    </Box>}
                </CardContent>
                {description && <CardContent sx={{ pt: '0px', pb: '12px' }}>
                    <Typography
                        sx={{
                            display: {xs: 'none', md: 'block'},
                            fontSize: '13px',
                            height: '40px',
                            overflow: 'hidden'
                        }}
                        variant='body2'
                        component='p'
                    >{description}</Typography>
                    <Typography
                        sx={{
                            display: {xs: 'block', md: 'none'},
                            fontSize: '13px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}
                        variant='body2'
                        component='p'
                    >{description}</Typography>
                </CardContent>}
                <CardActions sx={{ pl: '16px', py: 0, display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{display: 'block'}}>
                        {(date || type || assistants) && <Box
                            sx={{
                                display: 'block',
                                py: 0,
                                pb: '10px'
                            }}
                        >
                            {date && <Typography
                                variant='body2'
                                component='p'
                                sx={{fontSize: '13px'}}
                            >{dayjs(date).format('MMMM D, YYYY')}</Typography>}
                            {type && <Typography
                                variant='body2'
                                component='p'
                                sx={{
                                    ml: '0!important',
                                    fontSize: '13px'
                                }}
                            >{type}</Typography>}
                            {pax && <Typography
                                variant='body2'
                                component='p'
                                sx={{
                                    ml: '0!important',
                                    fontSize: '13px'
                                }}
                            >{getAssistantsString(pax)}</Typography>}
                        </Box>}
                        {(cancelable || time || languages) && !isCartModal && <Box
                            sx={{
                                display: { xs: 'none', md: 'flex' },
                                mb: '10px'
                            }}
                        >
                            {cancelable && <>
                                <XSquare />
                                <Typography
                                    variant='body2'
                                    component='p'
                                    color='secondary'
                                >&nbsp;Cancelación gratuita&nbsp;</Typography>
                            </>}
                            {time && <>
                                <Clock />
                                <Typography
                                    variant='body2'
                                    component='p'
                                    color='secondary'
                                >&nbsp;{time}&nbsp;</Typography>
                            </>}
                            {languages && <>
                                <Globe />
                                <Typography
                                    variant='body2'
                                    component='p'
                                    color='secondary'
                                >&nbsp;Idiomas: {languages}</Typography>
                            </>}
                        </Box>}
                        {cancelable && <Box sx={{
                            display: { xs: 'flex', md: 'none' },
                            ml: '0px!important'
                        }}>
                            <XSquare />
                            <Typography
                                variant='body2'
                                component='p'
                                color='secondary'
                            >&nbsp;Cancelación gratuita&nbsp;</Typography>
                        </Box>}
                        {(rate || reviews) && <Box sx={{display: 'flex', mt: '15px'}}>
                            {rate && <Box
                                sx={{
                                    width: 40,
                                    height: 28,
                                    borderRadius: 2,
                                    bgcolor: 'primary.main',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                <Typography
                                    variant="h6"
                                    color="primary.contrastText"
                                    sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                    {rate}
                                </Typography>
                            </Box>}
                            {reviews && <>
                                <Typography
                                    mb={2}
                                    pl={1}
                                    fontFamily={'Poppins'}
                                    sx={{
                                        display: { xs: 'none', md: 'flex' },
                                        lineHeight: '1.9'
                                    }}>
                                    {`${reviews.state}`}
                                </Typography>
                                <Typography
                                    mb={2}
                                    fontFamily={'Poppins'}
                                    sx={{
                                        fontSize: { xs: '14px', md: '16px' },
                                        lineHeight: '1.9'
                                    }}>
                                    {`(${reviews.number} Reviews)`}
                                </Typography>
                            </>}
                        </Box>}
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                fontSize: { xs: '14px', md: '18px' },
                                fontWeight: { xs: 600, md: 700 },
                                color: 'secondary.main'
                            }}>
                            {isCart || isCartModal ? `${price} €` :`Desde ${price} €`}
                        </Typography>
                        <Typography
                            color="grayText"
                            sx={{
                                textAlign: 'right',
                                fontSize: { xs: '10px', md: '12px' },
                                fontWeight: 400
                            }}>
                            IVA incluido
                        </Typography>
                    </Box>
                </CardActions>
            </Box>
        </Card>
    )
};

export default ProductCard2;
