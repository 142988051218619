import { ReactComponent as InfoIcon } from '../../assets/icons/info-white.svg'
import { ReactComponent as CameraIcon } from '../../assets/icons/camera.svg'
import { ReactComponent as BagpackIcon } from '../../assets/icons/bagpack.svg'
import { ReactComponent as TicketIcon } from '../../assets/icons/ticket.svg'
import { ReactComponent as CampTentIcon } from '../../assets/icons/camp-tent.svg'
import { ReactComponent as PlateIcon } from '../../assets/icons/plate.svg'
import './styles.css'

const BlogArticleButtons = ({ placeName, activePath, path }) => {
    const articleButtons = [
        {
            name: 'Información principal',
            path: `${path}`,
            Icon: InfoIcon
        },
        {
            name: `Qué ver en ${placeName}`,
            path: `${path}/que-ver`,
            Icon: CameraIcon
        },
        {
            name: `Excursiones en ${placeName}`,
            path: `${path}/excursiones`,
            Icon: BagpackIcon
        },
        {
            name: `Qué hacer en ${placeName}`,
            path: `${path}/que-hacer`,
            Icon: TicketIcon
        },
        {
            name: `Dónde dormir en ${placeName}`,
            path: `${path}/donde-dormir`,
            Icon: CampTentIcon
        },
        {
            name: `Gastronomía en ${placeName}`,
            path: `${path}/gastronomia`,
            Icon: PlateIcon
        }
    ]
    const isOnSomeButtonPage = articleButtons.some(
        (btn) => btn.path === activePath
    )
    if (!placeName || !isOnSomeButtonPage) return null

    return (
        <ul className="btn-list">
            {articleButtons.map(({ name, path, Icon }, index) => (
                <li key={`${name}${index}`} className="list-item_article">
                    <a
                        href={path}
                        className="list-item_link"
                        style={{
                            backgroundColor:
                                path === activePath ? '#223F7F' : '#40C5E8'
                        }}>
                        <Icon
                            style={{ marginBottom: '.5rem' }}
                            height={36}
                            width={36}
                            className="list-icon"
                        />
                        <span>{name}</span>
                    </a>
                </li>
            ))}
        </ul>
    )
}

export default BlogArticleButtons
