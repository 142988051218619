import React from "react";

import {
    Box,
    Button,
    Card,
    CardContent,
} from "@mui/material";
import ResumenAccordion from "./resumenAccordion";

const CardResumen = ({isFormValid = true, handleNext}) => {
    return (
        <Card sx={{
            my: 0,
            borderRadius: 0,
            position: 'fixed',
            bottom: 0,
            right: 0,
            width: '100%',
            boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 6px 6px rgba(0,0,0,0.08)'
        }}>
            <CardContent>
                <ResumenAccordion />
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: '15px'
                }}>
                    <Button
                        variant="contained"
                        onClick={handleNext}
                        disabled={!isFormValid}
                        sx={{
                            width: '100%'
                        }}
                    >Siguiente</Button>
                </Box>
            </CardContent>
        </Card>
    )
}

export default CardResumen;