import React from 'react'
import Hero from '../../components/Hero'
import GuideCards from '../../components/GuideCards'
import DestinationGallery from '../../components/DestinationGallery'
import GuidesTabs from '../../components/GuidesTabs'
import heroImage from '../../assets/images/trekzy-blue-background.png'
import { destinations, guidesByRegion } from '../../utils/data'
import MainLayout from '../../components/MainLayout'

const GuiasDeViaje = () => (
    <MainLayout>
        <Hero
            bgImage={heroImage}
            placeHolderText="Busca tu guía de viaje"
            title="Guías de viaje"
            text="Hemos preparado las guías de viaje más completas. Visita las ciudades que quieras sin miedo a perderte algo."
            searchSource="guides"
        />
        <GuideCards />
        <DestinationGallery title="Top guías" destinations={destinations} />
        <GuidesTabs tabsData={guidesByRegion} />
    </MainLayout>
)

export default GuiasDeViaje
